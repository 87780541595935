import { Loader, useEmployees } from '@portal/frontend/react';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { MONTHS } from './months';

interface MonthlyEmployeeFormProps {
  onSubmitHandler: (values: {
    employeeId: string;
    month: number;
    year: number;
  }) => void;
}

const initialTouched = {
  employeeId: false,
  year: false,
  month: false,
};

const NOW = new Date();

const validationSchema = Yup.object().shape({
  employeeId: Yup.string().uuid().required('Employee is required'),
  year: Yup.number().min(0).required('Year is required'),
  month: Yup.number().required('Month is required'),
});

export const MonthlyEmployeeForm: FC<MonthlyEmployeeFormProps> = ({
  onSubmitHandler,
}) => {
  const { employees } = useEmployees();

  const { handleChange, handleSubmit, values, setTouched, errors, touched } =
    useFormik<{
      employeeId: string;
      year: number;
      month: number;
    }>({
      initialValues: {
        employeeId: employees?.length ? employees[0]?.id : '',
        year: NOW.getFullYear(),
        month: NOW.getMonth() + 1,
      },
      initialTouched: initialTouched,
      validationSchema,
      onSubmit: onSubmitHandler,
    });

  useEffect(() => {
    setTouched(initialTouched);
  }, [setTouched]);

  if (!employees) {
    return <Loader fullScreen={true} />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col className="col-4">
          <Form.Group>
            <Form.Label>Select Employee</Form.Label>
            <Form.Control
              as="select"
              name="employeeId"
              value={values.employeeId}
              onChange={handleChange}
            >
              {employees.map((employee) => (
                <option
                  key={`${employee.id}`}
                  value={employee.id}
                >{`${employee.name} ${employee.surname}`}</option>
              ))}
            </Form.Control>
            {touched.employeeId && errors.employeeId && (
              <span style={{ fontSize: 12 }} className="text-danger">
                {errors.employeeId}
              </span>
            )}
          </Form.Group>
        </Col>
        <Col className="col-4">
          <Form.Group>
            <Form.Label>Select year</Form.Label>
            <Form.Control
              name="year"
              onChange={handleChange}
              value={values.year}
            />
          </Form.Group>
        </Col>
        <Col className="col-4">
          <Form.Group>
            <Form.Label>Select month</Form.Label>
            <Form.Control
              as="select"
              name="month"
              value={values.month}
              onChange={handleChange}
            >
              {MONTHS.map((month, idx) => (
                <option key={month} value={idx + 1}>
                  {month}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-3">
        <Col className="col-3">
          <Button type="submit" variant="primary">
            Show results
          </Button>
        </Col>
        <Col className="col-3">
          <Button onClick={() => window.print()} variant="outline-primary">
            Print report
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
