import moment from 'moment';
import axios from 'axios';
import { WorkHoursMonthSummaryDto } from '@portal/client-portal-api-model';

export async function getWorkHoursMonthSummary(
  url: string,
  {
    arg,
  }: {
    arg: {
      startDate: Date;
      endDate: Date;
    };
  },
): Promise<WorkHoursMonthSummaryDto> {
  const params = {
    startDate: moment(arg.startDate).format('YYYY-MM-DD'),
    finishDate: moment(arg.endDate).format('YYYY-MM-DD'),
  };

  return axios.get(url, { params }).then((response) => response.data);
}
