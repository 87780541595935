import { ColumnsIds } from '../../enums/ColumnsIds';
import { Role } from '../../interfaces/Role';
import { RowCells } from '../../interfaces/reactgrid.interface';
import { CellStyle } from '@silevis/reactgrid';

const headerCellStyle: CellStyle = {
  background: '#007bff',
  color: 'white',
};

const getRolesHeaderCells = (roles: Role[]): RowCells[] => {
  return roles.reduce<RowCells[]>((rolesHeaderCell, role) => {
    rolesHeaderCell.push(
      {
        type: 'header',
        text: role.name,
        style: headerCellStyle,
        columnId: `${role.id}-${ColumnsIds.PLANNED_HOURS}`,
      },
      {
        type: 'header',
        text: `${role.name}`,
        style: headerCellStyle,
        columnId: `${role.id}-${ColumnsIds.ACTUAL_HOURS}`,
      }
    );
    return rolesHeaderCell;
  }, []);
};

export const getHeaders = (roles: Role[]): RowCells[] => {
  return [
    {
      type: 'header',
      text: 'WORK ITEM',
      columnId: ColumnsIds.NAME,
      style: headerCellStyle,
    },
    {
      type: 'header',
      text: 'ID',
      columnId: ColumnsIds.EXT_ID,
      style: headerCellStyle,
    },
    ...getRolesHeaderCells(roles),
    {
      type: 'header',
      text: 'PRICE',
      columnId: ColumnsIds.PRICE,
      style: headerCellStyle,
    },
    {
      type: 'header',
      text: 'BUDGET LEFT',
      columnId: ColumnsIds.BUDGET_LEFT,
      style: headerCellStyle,
    },
    {
      type: 'header',
      text: 'ACTUAL PRICE',
      columnId: ColumnsIds.ACTUAL_PRICE,
      style: headerCellStyle,
    },
    {
      type: 'header',
      text: 'PROGRESS',
      columnId: ColumnsIds.PROGRESS,
      style: headerCellStyle,
    },
    {
      type: 'header',
      text: 'START DATE',
      columnId: ColumnsIds.START_DATE,
      style: headerCellStyle,
    },
    {
      type: 'header',
      text: 'DEADLINE',
      columnId: ColumnsIds.DEADLINE,
      style: headerCellStyle,
    },
    {
      type: 'header',
      text: 'STATUS',
      columnId: ColumnsIds.STATUS,
      style: headerCellStyle,
    },
    {
      type: 'header',
      text: '-',
      columnId: ColumnsIds.ARCHIVE,
      style: headerCellStyle,
    },
  ];
};
