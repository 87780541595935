import React, { FC, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ProjectDto } from '@portal/client-portal-api-model';
import { ActionSpinner } from '@portal/frontend/react';

interface ArchiveProjectModalProps {
  onHide: () => void;
  onConfirm: () => Promise<void>;
  show: boolean;
  project: ProjectDto | undefined;
}

export const ArchiveProjectModal: FC<ArchiveProjectModalProps> = ({
  onHide,
  show,
  onConfirm,
  project,
}) => {
  const [archivingProject, setArchivingProject] = useState(false);

  const handleOnHide = () => {
    onHide();
    setArchivingProject(false);
  };

  if (!project) {
    return null;
  }

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      onHide={handleOnHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          You are going to archive "{project.name}" project
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You will deactivate all workitems in this project and collaborators
        won't be able to log their time against them anymore. Are you sure to
        proceed?
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOnHide} variant="outline-primary">
          No
        </Button>
        <Button
          variant="danger"
          disabled={archivingProject}
          onClick={async () => {
            setArchivingProject(true);
            try {
              await onConfirm();
            } catch (error) {
              console.error(error);
            } finally {
              handleOnHide();
            }
          }}
        >
          <ActionSpinner
            showSpinner={archivingProject}
            defaultText={'Yes'}
            spinnerContent={'Archiving...'}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArchiveProjectModal;
