import useSWRMutation from 'swr/mutation';
import Endpoint from '../enums/apiEndpoints';
import { getBankHolidays } from '../functions/getBankHolidays';

export function useBankHolidays() {
  const { trigger, data, isMutating } = useSWRMutation(
    Endpoint.BANK_HOLIDAYS,
    getBankHolidays,
  );

  return {
    data,
    trigger,
    isMutating,
  };
}
