import { Dispatch, SetStateAction, useCallback, useState } from 'react';

type Id = string | number;

const useArray = <T extends { id: Id }>(
  initial: T[]
): [
  T[],
  Dispatch<SetStateAction<T[]>>,
  (newItem: T) => void,
  (updatedItem: T) => void,
  (id: Id) => void,
  (updatedItems: T[]) => void
] => {
  const [values, setValues] = useState<T[]>(initial);

  const update = useCallback(
    (updatedItem) =>
      setValues((arr) =>
        arr.map((item) =>
          item.id === updatedItem.id ? { ...item, ...updatedItem } : item
        )
      ),
    []
  );

  const updateMany = useCallback(
    (updatedItems: T[]) =>
      setValues((arr) =>
        arr.map((item) => {
          const foundItem = updatedItems.find((u) => u.id === item.id);
          return foundItem ? foundItem : item;
        })
      ),
    []
  );

  const add = useCallback((a) => setValues((v) => [...v, a]), []);

  const remove = useCallback(
    (id) => setValues((arr) => arr.filter((v) => v && v.id !== id)),
    []
  );

  return [values, setValues, add, update, remove, updateMany];
};

export default useArray;
