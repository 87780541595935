import axios from 'axios';
import { BankHolidayDto } from '../interfaces/BankHoliday.interface';

export async function updateBankHolidays(
  url: string,
  {
    arg,
  }: {
    arg: Partial<BankHolidayDto>;
  },
): Promise<BankHolidayDto> {
  const { id, ...body } = arg;
  return axios.put(`${url}/${arg.id}`, body).then((response) => response?.data);
}
