import React, { FC } from 'react';
import moment from 'moment';
import { getStatusColor } from './getStatusColor';
import { WorkHoursMonthSummaryDto } from '@portal/client-portal-api-model';

interface WorkHoursSummaryTableProps {
  data: WorkHoursMonthSummaryDto;
}

const WorkHoursSummaryTable: FC<WorkHoursSummaryTableProps> = ({ data }) => {
  const workHoursMonthSummaryRow = data?.workHoursMonthSummaryRow;
  const hasSummaryItems = Boolean(workHoursMonthSummaryRow.length);

  if (!hasSummaryItems) {
    return <div>This report has no items to display</div>;
  }

  return (
    <table className="summary-table">
      <thead>
        <tr>
          <th>Employee</th>

          {hasSummaryItems &&
            workHoursMonthSummaryRow[0]?.workHoursMonthSummaryItems?.map(
              (item) => (
                <th
                  key={`${item.workHours}-${item.date}-${item.status}`}
                  className="vertical-header"
                >
                  <div className="w-100 d-flex align-items-center">
                    {moment(item.date).format('DD-MM-YYYY')}
                  </div>
                </th>
              ),
            )}

          <th className="summary-table-last-column">
            Sum Employee Hours Month
          </th>
        </tr>
      </thead>

      <tbody>
        {hasSummaryItems &&
          workHoursMonthSummaryRow?.map((employee) => (
            <tr key={employee.id}>
              <td className="text-nowrap">{`${employee.name} ${employee.surname}`}</td>

              {employee?.workHoursMonthSummaryItems?.map((item) => (
                <td
                  key={`${item.workHours}-${item.date}-${item.status}`}
                  style={{ backgroundColor: getStatusColor(item.status) }}
                >
                  {item.workHours !== null ? item.workHours : ''}
                </td>
              ))}

              <td
                className="summary-table-last-column"
                style={{
                  backgroundColor: getStatusColor(
                    employee.sumEmployeeHoursMonth.status,
                  ),
                }}
              >
                {employee.sumEmployeeHoursMonth.workHours}
              </td>
            </tr>
          ))}

        <tr className="summary-table-last-row">
          <td>Total hours</td>

          {data?.workHoursMonthSummaryDaily && (
            <>
              {data.workHoursMonthSummaryDaily.sumAllEmployeesHoursDay.map(
                (item) => (
                  <td key={`${item.workHours}-${item.date}-${item.status}`}>
                    {item.workHours}
                  </td>
                ),
              )}

              <td>{data.workHoursMonthSummaryDaily.sumAllHours.workHours}</td>
            </>
          )}
        </tr>
      </tbody>
    </table>
  );
};

export default WorkHoursSummaryTable;
