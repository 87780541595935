import { ColumnsIds } from '../../enums/ColumnsIds';
import { WorkItemStatus } from '../../enums/WorkItemStatus';
import { RowCells } from '../../interfaces/reactgrid.interface';
import { Role } from '../../interfaces/Role';
import { WorkItem } from '../../interfaces/WorkItem';
import { getActualPrice } from './getActualPrice';
import { getBudgetLeft } from './getBudgetLeft';
import { getPrice } from './getWorkItemPrice';

const statuses = Object.keys(WorkItemStatus).map((key) => WorkItemStatus[key]);

const NON_EDITABLE_STATUSES = [
  WorkItemStatus.ACCEPTED,
  WorkItemStatus.ESTIMATED,
  WorkItemStatus.IN_REVIEW,
  WorkItemStatus.IN_WORK,
  WorkItemStatus.ORDERED,
  WorkItemStatus.BILLED,
] as const;

const getRolesCells = (workItem: WorkItem, roles: Role[]): RowCells[] => {
  return roles.reduce((rolesCells, role) => {
    const workItemRoleHours = workItem.workItemRoleHours?.find(
      (featureWorkHour) => featureWorkHour.role.id === role.id
    );
    rolesCells.push(
      {
        type: 'number',
        nonEditable: NON_EDITABLE_STATUSES.some(
          (status) => status === workItem.status
        ),
        value: workItemRoleHours?.plannedHours || 0,
        style: { background: '#69adf5', color: 'white' },
        hideZero: true,
        columnId: `${role.id}-${ColumnsIds.PLANNED_HOURS}`,
      },
      {
        type: 'number',
        value: workItemRoleHours?.actualHours || 0,
        style: {
          background: '#b4dafd',
          color: getTextColor(
            workItemRoleHours?.plannedHours || 0,
            workItemRoleHours?.actualHours || 0
          ),
        },
        hideZero: true,
        nonEditable: true,
        columnId: `${role.id}-${ColumnsIds.ACTUAL_HOURS}`,
      }
    );
    return rolesCells;
  }, []);
};

const getTextColor = (price: number, actualPrice: number) =>
  actualPrice > price ? 'red' : 'green';

const getProgressColor = (progress: number) => {
  if (progress <= 40) {
    return 'red';
  }
  if (progress > 40 && progress <= 60) {
    return 'orange';
  }
  if (progress > 60) {
    return 'green';
  }
};

export const getCells = (workItem: WorkItem, roles: Role[]): RowCells[] => {
  const actualPrice = getActualPrice(workItem.workItemRoleHours, roles);
  const price = getPrice(workItem.workItemRoleHours, roles);
  const budgetLeft = getBudgetLeft(workItem.workItemRoleHours, roles);
  return [
    {
      type: 'text',
      text: workItem.extId,
      columnId: ColumnsIds.EXT_ID,
      style: { background: 'white' },
    },
    ...getRolesCells(workItem, roles),
    {
      type: 'number',
      nonEditable: true,
      value: price,
      columnId: ColumnsIds.PRICE,
      style: { background: 'white' },
    },
    {
      type: 'number',
      value: budgetLeft,
      nonEditable: true,
      columnId: ColumnsIds.BUDGET_LEFT,
      style: { background: '#b4dafd', color: 'black' },
    },
    {
      type: 'number',
      value: actualPrice,
      nonEditable: true,
      columnId: ColumnsIds.ACTUAL_PRICE,
      style: { background: '#b4dafd', color: getTextColor(price, actualPrice) },
    },
    {
      type: 'number',
      value: workItem.progress,
      columnId: ColumnsIds.PROGRESS,
      hideZero: true,
      style: {
        background: 'white',
        color: getProgressColor(workItem.progress),
      },
    },
    {
      type: 'date',
      date: new Date(workItem.startDate),
      columnId: ColumnsIds.START_DATE,
      style: { background: 'white' },
    },
    {
      type: 'date',
      date: new Date(workItem.deadLine),
      columnId: ColumnsIds.DEADLINE,
      style: { background: 'white' },
    },
    {
      type: 'customDropdown',
      values: statuses,
      text: workItem.status,
      columnId: ColumnsIds.STATUS,
      style: { background: 'white' },
    },
    {
      type: 'archiveButton',
      text: '',
      columnId: ColumnsIds.ARCHIVE,
      style: { background: 'white' },
    },
  ];
};
