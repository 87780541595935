import React from 'react';
import { Container } from 'react-bootstrap';
import { NavBar } from '../components/NavBar';
import { Outlet } from 'react-router-dom';

export const HomePage = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <NavBar />
      <Container fluid className="d-md-flex h-100 overflow-hidden-md">
        <Outlet />
      </Container>
    </div>
  );
};

export default HomePage;
