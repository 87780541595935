import {
  EmployeeDto,
  UpdateCollaboratorDto,
} from '@portal/client-portal-api-model';
import { ButtonCellTemplate, Loader, useModal } from '@portal/frontend/react';
import { CellChange, Id, ReactGrid } from '@silevis/reactgrid';
import Axios, { AxiosResponse } from 'axios';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Endpoint from '../../enums/apiEndpoints';
import { getCollaboratorsTabColumns } from '../../functions';
import useArray from '../../hooks/useArray';
import { Collaborator } from '../../interfaces/Collabolator';
import { Role } from '../../interfaces/Role';
import {
  RowCells,
  SilevisPortalRow,
} from '../../interfaces/reactgrid.interface';
import AddCollaboratorModal, {
  AddCollaboratorFormValues,
} from './AddCollaboratorModal';

interface CollaboratorsTabProps {
  projectId: string;
}

export const CollaboratorsTab: FC<CollaboratorsTabProps> = (props) => {
  const { projectId } = props;
  const [visible, open, close] = useModal();
  const [collaborators, setCollaborators, add, update] = useArray<Collaborator>(
    [],
  );
  const [roles, setRoles] = useState<Role[]>([]);
  const [employees, setEmployees] = useState<EmployeeDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    if (!projectId) {
      return;
    }
    setIsLoading(true);
    Axios.all([
      Axios.get(`${Endpoint.PROJECT_COLLABORATORS}/${projectId}`),
      Axios.get(`${Endpoint.PROJECT_ROLES}/${projectId}`),
      Axios.get(`${Endpoint.EMPLOYEES}`),
    ]).then(
      Axios.spread(
        (collaboratorsResponse, rolesResponse, employeesResponse) => {
          setCollaborators(collaboratorsResponse.data);
          setRoles(rolesResponse.data);
          setEmployees(employeesResponse.data);
          setIsLoading(false);
        },
      ),
    );
  }, [projectId, setCollaborators]);

  useEffect(() => {
    fetchData().catch();
  }, [fetchData]);

  if (!collaborators) {
    return null;
  }

  const rows: SilevisPortalRow[] = [
    {
      rowId: 0,
      height: 45,
      cells: [
        {
          type: 'header',
          text: 'Project',
          style: { background: '#007bff', color: 'white' },
        },
        {
          type: 'header',
          text: 'Role',
          style: { background: '#007bff', color: 'white' },
        },
        {
          type: 'header',
          text: 'Employee',
          style: { background: '#007bff', color: 'white' },
        },
        {
          type: 'header',
          text: 'Action',
          style: { background: '#007bff', color: 'white' },
        },
      ],
    },
    ...collaborators
      .sort((firstValue, secondValue) =>
        firstValue.employee.name.toLowerCase() >=
        secondValue.employee.name.toLowerCase()
          ? 1
          : -1,
      )
      .map<SilevisPortalRow>((collaborator) => {
        const textColor = collaborator.deletedAt ? '#aaa' : '#000';
        return {
          rowId: collaborator.id,
          height: 35,
          cells: [
            {
              type: 'text',
              text: collaborator.project.name,
              nonEditable: true,
              style: { background: 'white', color: textColor },
            },
            {
              type: 'text',
              text: collaborator.role.name,
              nonEditable: true,
              style: {
                background: 'white',
                color: collaborator.role.deletedAt ? '#aaa' : textColor,
              },
            },
            {
              type: 'text',
              text: `${collaborator.employee.name} ${collaborator.employee.surname}`,
              nonEditable: true,
              style: { background: 'white', color: textColor },
            },
            {
              type: 'button',
              value: 'Delte',
              text: collaborator.deletedAt ? 'Restore' : 'Delete',
              style: { background: 'white' },
              variant: collaborator.deletedAt ? 'link' : 'danger',
              context: collaborator.deletedAt ? 'restore' : 'delete',
            },
          ],
        };
      }),
  ];

  if (
    isLoading ||
    !employees ||
    !roles ||
    employees.length < 1 ||
    roles.length < 1
  ) {
    return <Loader fullScreen></Loader>;
  }

  const handleAddCollaborator = async (values: AddCollaboratorFormValues) => {
    const { data } = await Axios.post<
      UpdateCollaboratorDto,
      AxiosResponse<Collaborator>
    >(Endpoint.COLLABORATORS, {
      roleId: values.roleId,
      employeeId: values.employeeId,
      projectId,
    });
    add(data);
  };

  const handleDeleteCollaborator = async (collaboratorId: Id) => {
    await Axios.delete(`${Endpoint.COLLABORATORS}/${collaboratorId}`);
    await fetchData();
  };

  const handleRestoreCollaborator = async (collaborator: Collaborator) => {
    const restoredCollaborator: UpdateCollaboratorDto = {
      employeeId: collaborator.employee.id,
      roleId: collaborator.role.id,
      deletedAt: null,
    };
    const { data } = await Axios.post(
      `${Endpoint.COLLABORATORS}/${collaborator.id}`,
      restoredCollaborator,
    );
    update(data);
  };

  const handleChanges = (changes: CellChange<RowCells>[]) => {
    changes.forEach(async (change) => {
      const foundCollaborator = collaborators.find(
        (collaborator) => collaborator.id === change.rowId,
      );

      if (change.columnId === 'action' && change.type === 'button') {
        if (change.newCell.context === 'restore') {
          handleRestoreCollaborator(foundCollaborator);
        }
        if (change.newCell.context === 'delete') {
          handleDeleteCollaborator(change.rowId);
        }
      }
    });
  };

  const isButtonDisabled = !projectId || !roles.length;

  const employeesAllowedForAddAsNewCollaborator = employees.filter(
    (employee) =>
      !collaborators.some(
        (collaborator) => collaborator.employee.id === employee.id,
      ) && !employee.deletedAt,
  );

  const rolesAllowedForAddAsNewCollaborator = roles.filter(
    (role) => !role.deletedAt,
  );

  return (
    <div className="h-100 d-flex flex-column">
      <div className="overflow-hidden d-flex">
        <div className="overflow-auto">
          <ReactGrid
            rows={rows}
            columns={getCollaboratorsTabColumns()}
            stickyTopRows={1}
            onCellsChanged={handleChanges}
            customCellTemplates={{
              button: new ButtonCellTemplate(),
            }}
          />
        </div>
      </div>

      <div className="pt-3">
        <Button
          style={{ width: '80px' }}
          size="sm"
          className="mr-3"
          onClick={() => open()}
          disabled={isButtonDisabled}
        >
          Add new{' '}
        </Button>
      </div>

      <AddCollaboratorModal
        show={visible}
        onHide={close}
        onSave={async (values) => {
          await handleAddCollaborator(values);
          close();
        }}
        employees={employeesAllowedForAddAsNewCollaborator}
        roles={rolesAllowedForAddAsNewCollaborator}
      />
    </div>
  );
};

export default CollaboratorsTab;
