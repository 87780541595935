import { Role } from '../../interfaces/Role';
import { WorkItemRoleHours } from '../../interfaces/WorkItemRoleHours';

export const getActualPrice = (
  workItemRoleHours: WorkItemRoleHours[],
  roles: Role[]
): number =>
  workItemRoleHours?.reduce((prev, workItemRoleHour) => {
    const foundRole = roles?.find(
      (role) => workItemRoleHour.role.id === role.id
    );
    if (foundRole) {
      prev = prev + (workItemRoleHour.actualHours || 0) * foundRole.hourlyRate;
    }
    return prev;
  }, 0);
