import { WorkItemDto } from '@portal/client-portal-api-model';
import Endpoint from '../enums/apiEndpoints';
import { useRequest } from '@portal/frontend/react';

export function useProjectsWorkItems(projectIds: string[]) {
  const {
    data: workItems,
    response,
    error,
    isValidating,
    isLoading,
    mutate,
    mutateData,
  } = useRequest<WorkItemDto[]>({
    url: Endpoint.PROJECT_WORK_ITEMS,
    method: 'POST',
    data: projectIds,
  });

  return {
    workItems: workItems,
    workItemsResponse: response,
    error,
    isValidating,
    isLoading,
    mutateWorkItemsResponse: mutate,
    mutateWorkItems: mutateData,
  };
}
