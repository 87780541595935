import { formatter } from '@portal/frontend/utils';
import React from 'react';
import { FC } from 'react';
import { Table } from 'react-bootstrap';
import { ReportTableProps } from './ReportTableProps.interface';

export const ProjectPeopleTotalsTable: FC<ReportTableProps> = ({
  report,
  showCosts,
  showHours,
  noBreakdown,
}) => {
  return (
    <>
      {report.length === 0 && <span>This report has no items to display</span>}
      {report.map((project) => {
        return (
          <Table
            bordered
            hover
            size="sm"
            style={{ width: '100%', borderCollapse: 'collapse' }}
            key={project.id}
          >
            <thead style={{ backgroundColor: '#9FC7E7' }}>
              <tr key={project.id}>
                <th colSpan={1} style={{ width: '60%' }}>
                  {project.name} {project.deletedAt ? '(archived)' : ''}
                </th>
                {showHours && (
                  <th
                    className="font-weight-bold"
                    style={{
                      textAlign: 'right',
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                      fontWeight: 'normal',
                    }}
                  >
                    {formatter.format(
                      project.collaborators
                        .flatMap((collaborator) =>
                          collaborator.timetableEntries.map(
                            (entry) => entry.hours
                          )
                        )
                        .reduce((a, b) => a + b)
                    )}
                  </th>
                )}
                {showCosts && (
                  <th
                    className="font-weight-bold"
                    style={{
                      textAlign: 'right',
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                      fontWeight: 'normal',
                    }}
                  >
                    {formatter.format(
                      project.collaborators
                        .flatMap((collaborator) =>
                          collaborator.timetableEntries.map(
                            (entry) =>
                              entry.hours * collaborator.role.hourlyRate
                          )
                        )
                        .reduce((a, b) => a + b)
                    )}
                  </th>
                )}
              </tr>
            </thead>
            <thead style={{ backgroundColor: '#F7F7F7' }}>
              <tr key={`${project.id}-headers`}>
                <th>Person</th>
                {showHours && (
                  <th
                    style={{
                      textAlign: 'right',
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                      fontWeight: 'normal',
                    }}
                  >
                    Sum of hours
                  </th>
                )}
                {showCosts && (
                  <th
                    style={{
                      textAlign: 'right',
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                      fontWeight: 'normal',
                    }}
                  >
                    Sum of costs
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {project.collaborators.map((collaborator) => {
                return (
                  <tr key={collaborator.id}>
                    <td>{`${collaborator.employee.name} ${collaborator.employee.surname}`}</td>
                    {showHours && (
                      <td
                        className="font-weight-bold"
                        style={{
                          width: '1px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {formatter.format(
                          collaborator.timetableEntries
                            .map((entry) => entry.hours)
                            .reduce((a, b) => a + b)
                        )}
                      </td>
                    )}
                    {showCosts && (
                      <td
                        className="font-weight-bold"
                        style={{
                          width: '1px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {formatter.format(
                          collaborator.timetableEntries
                            .map(
                              (entry) =>
                                entry.hours * collaborator.role.hourlyRate
                            )
                            .reduce((a, b) => a + b)
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        );
      })}
    </>
  );
};
