import {
  ButtonCellTemplate,
  LoadingOverlay,
  useModal,
} from '@portal/frontend/react';
import { CellChange, ReactGrid } from '@silevis/reactgrid';
import Axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import AddBankHolidayModal from '../components/admin-page/AddBankHolidayModal';
import RemoveBankHolidayModal from '../components/admin-page/RemoveBankHolidayModal';
import Endpoint from '../enums/apiEndpoints';
import { useBankHolidays } from '../hooks/useBankHolidays';
import {
  BankHoliday,
  BankHolidayDto,
} from '../interfaces/BankHoliday.interface';
import { RowCells, SilevisPortalRow } from '../interfaces/reactgrid.interface';
import { useUpdateBankHoliday } from '../hooks/useUpdateBankHoliday';
import { bankHolidayTabColumns } from '../consts/BankHolidayTabColumns';

interface SelectYearValues {
  year: number;
}

const validationSchema = Yup.object().shape({
  year: Yup.number().min(2000).required('Year is required'),
});

export const BankHolidayView = () => {
  const [showAddModal, openAddModal, closeAddModal] = useModal();
  const [showDeleteModal, openDeleteModal, closeDeleteModal] = useModal();
  const [selectedBankHoliday, setSelectedBankHoliday] =
    useState<BankHolidayDto | null>(null);

  const currentYear = moment().year();

  const {
    data: bankHolidays,
    trigger: triggerGetBankHoliday,
    isMutating,
  } = useBankHolidays();
  const {
    trigger: triggerUpdateBankHoliday,
    isMutating: isMutatingUpdateBankHoliday,
  } = useUpdateBankHoliday();

  useEffect(() => {
    triggerGetBankHoliday({ year: currentYear });
  }, [currentYear, triggerGetBankHoliday]);

  const { handleSubmit, handleChange, errors, values } =
    useFormik<SelectYearValues>({
      initialValues: {
        year: currentYear,
      },
      onSubmit: (values) => {
        triggerGetBankHoliday({ year: values.year });
      },
      validationSchema,
    });

  const rows: SilevisPortalRow[] = [
    {
      rowId: 0,
      height: 45,
      cells: [
        {
          type: 'header',
          text: 'Date',
          style: { background: '#007bff', color: 'white' },
        },
        {
          type: 'header',
          text: 'Name',
          style: { background: '#007bff', color: 'white' },
        },
        {
          type: 'header',
          text: 'Description',
          style: { background: '#007bff', color: 'white' },
        },

        {
          type: 'header',
          text: 'Is periodic',
          style: { background: '#007bff', color: 'white' },
        },

        {
          type: 'header',
          text: 'Action',
          style: { background: '#007bff', color: 'white' },
        },
      ],
    },
    ...(bankHolidays?.map<SilevisPortalRow>((holiday) => {
      return {
        rowId: holiday.id,
        height: 35,
        cells: [
          {
            type: 'date',
            date: moment.isDate(holiday.date)
              ? holiday.date
              : moment(`${values.year}-${holiday.date}`, 'YYYY-MM-DD').toDate(),
            nonEditable: false,
            format: new Intl.DateTimeFormat('en-EN', {
              day: 'numeric',
              month: 'long',
            }),
            style: {
              background: 'white',
              color: 'black',
            },
          },
          {
            type: 'text',
            text: holiday.name,
            nonEditable: false,
            style: { background: 'white', color: 'black' },
          },
          {
            type: 'text',
            text: holiday.description,
            nonEditable: false,
            style: { background: 'white', color: 'black' },
          },

          {
            type: 'checkbox',
            checked: holiday.isPeriodic,
            nonEditable: false,
            style: { background: 'white', color: 'black' },
          },
          {
            type: 'button',
            value: 'Delete',
            text: 'Delete',
            variant: 'danger',
            style: { background: 'white' },
            context: 'delete',
          },
        ],
      };
    }) ?? []),
  ];

  const handleConfirmDeleteBankHoliday = async () => {
    await Axios.delete(`${Endpoint.BANK_HOLIDAYS}/${selectedBankHoliday.id}`);

    triggerGetBankHoliday({ year: values.year });
    setSelectedBankHoliday(null);
  };

  const handleCloseDeleteModal = () => {
    closeDeleteModal();
    setSelectedBankHoliday(null);
  };

  const handleAddNewBankHoliday = async (bankHoliday: BankHoliday) => {
    await Axios.post<BankHoliday>(`${Endpoint.BANK_HOLIDAYS}/`, bankHoliday);

    triggerGetBankHoliday({ year: values.year });

    closeAddModal();
  };

  const getChangedFields = (
    original: BankHolidayDto,
    updated: BankHolidayDto,
  ) => {
    const changes = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        changes[key] = updated[key];
      }
    }
    return changes;
  };

  const handleUpdateBankHoliday = async (
    originalBankHoliday: BankHolidayDto,
    bankHolidayToUpdate: BankHolidayDto,
  ) => {
    const changedFields = getChangedFields(
      originalBankHoliday,
      bankHolidayToUpdate,
    );

    if (Object.keys(changedFields).length > 0) {
      await triggerUpdateBankHoliday({
        id: bankHolidayToUpdate.id,
        ...changedFields,
      });

      triggerGetBankHoliday({ year: values.year });
    }
  };

  const handleChanges = (changes: CellChange<RowCells>[]) => {
    changes.forEach(async (change) => {
      const foundBankHoliday = bankHolidays.find(
        (bankHoliday) => bankHoliday.id === change.rowId,
      );

      if (
        change.columnId === 'date' &&
        change.type === 'date' &&
        moment(change.newCell.date).isValid()
      ) {
        handleUpdateBankHoliday(foundBankHoliday, {
          ...foundBankHoliday,
          date: change.newCell.date,
        });
      }

      if (change.columnId === 'name' && change.type === 'text') {
        handleUpdateBankHoliday(foundBankHoliday, {
          ...foundBankHoliday,
          name: change.newCell.text,
        });
      }

      if (change.columnId === 'description' && change.type === 'text') {
        handleUpdateBankHoliday(foundBankHoliday, {
          ...foundBankHoliday,
          description: change.newCell.text,
        });
      }

      if (change.columnId === 'isPeriodic' && change.type === 'checkbox') {
        handleUpdateBankHoliday(foundBankHoliday, {
          ...foundBankHoliday,
          isPeriodic: change.newCell.checked,
          date: moment(
            `${values.year}-${foundBankHoliday.date}`,
            'YYYY-MM-DD',
          ).toDate(),
        });
      }

      if (change.type === 'button' && change.newCell.context === 'delete') {
        setSelectedBankHoliday(foundBankHoliday);
        openDeleteModal();
      }
    });
  };

  return (
    <div className="p-5 h-100">
      <div>
        <Row>
          <Col>
            <Form
              onSubmit={handleSubmit}
              className="d-flex align-items-center justify-content-center flex-row"
            >
              <Form.Group className="d-flex align-items-center justify-content-center">
                <Form.Label
                  style={{ width: '200px' }}
                  className="d-flex align-items-center justify-content-center mx-1"
                >
                  Select Year
                </Form.Label>
                <Form.Control
                  value={values.year}
                  type="number"
                  name="year"
                  placeholder="Year"
                  required
                  onChange={handleChange}
                  className="mx-1"
                />
                <Form.Text className="error mx-1">{errors.year}</Form.Text>
              </Form.Group>

              <Form.Group className="d-flex align-items-center justify-content-center">
                <Button
                  style={{
                    width: '200px',
                    height: '38px',
                  }}
                  type="submit"
                  variant="primary"
                  disabled={isMutating || isMutatingUpdateBankHoliday}
                >
                  Show Bank Holidays
                </Button>
              </Form.Group>
            </Form>
          </Col>

          <Col className="d-flex justify-content-end">
            <Button
              className="mb-1"
              style={{ width: '100px', height: '38px' }}
              onClick={() => openAddModal()}
            >
              Add new
            </Button>
          </Col>
        </Row>
      </div>

      <div className="position-relative overflow-hidden d-flex">
        <LoadingOverlay show={isMutating || isMutatingUpdateBankHoliday}>
          <div className="overflow-auto">
            <ReactGrid
              rows={rows}
              columns={bankHolidayTabColumns}
              onCellsChanged={handleChanges}
              stickyTopRows={1}
              customCellTemplates={{
                button: new ButtonCellTemplate(),
              }}
            />
          </div>
        </LoadingOverlay>
      </div>

      {selectedBankHoliday && showDeleteModal && (
        <RemoveBankHolidayModal
          show={showDeleteModal}
          bankHoliday={selectedBankHoliday}
          onConfirm={handleConfirmDeleteBankHoliday}
          onHide={handleCloseDeleteModal}
        />
      )}

      {showAddModal && (
        <AddBankHolidayModal
          onHide={closeAddModal}
          onSave={(values) => handleAddNewBankHoliday(values)}
          show={showAddModal}
          year={values.year}
        />
      )}
    </div>
  );
};

export default BankHolidayView;
