import { Column } from '@silevis/reactgrid';
import { ColumnsIds } from '../../enums/ColumnsIds';
import { Role } from '../../interfaces/Role';

const getRolesColumns = (roles: Role[]): Column[] => {
  const rolesColumns: Column[] = roles.reduce((rolesColumns, role) => {
    rolesColumns.push(
      { columnId: `${role.id}:${ColumnsIds.PLANNED_HOURS}`, width: 120 },
      { columnId: `${role.id}:${ColumnsIds.ACTUAL_HOURS}`, width: 120 }
    );
    return rolesColumns;
  }, []);
  return rolesColumns;
};

export const getColumns = (roles: Role[]): Column[] => {
  const columns: Column[] = [
    { columnId: ColumnsIds.NAME, width: 220, resizable: true },
    { columnId: ColumnsIds.EXT_ID, width: 120 },
    ...getRolesColumns(roles),
    { columnId: ColumnsIds.PRICE, width: 90 },
    { columnId: ColumnsIds.BUDGET_LEFT, width: 120 },
    { columnId: ColumnsIds.ACTUAL_PRICE, width: 120 },
    { columnId: ColumnsIds.PROGRESS, width: 90 },
    { columnId: ColumnsIds.START_DATE, width: 140 },
    { columnId: ColumnsIds.DEADLINE, width: 140 },
    { columnId: ColumnsIds.STATUS, width: 120 },
    { columnId: ColumnsIds.ARCHIVE, width: 60 },
  ];
  return columns;
};
