import { CreateEmployeeDto } from '@portal/client-portal-api-model';
import React, { FC } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UserRole } from '@portal/frontend/react';

interface AddEmployeeModalProps {
  onHide: () => void;
  onSave: (employee: CreateEmployeeDto) => void;
  show: boolean;
}

interface AddEmployeeFormValues {
  name: string;
  surname: string;
  email: string;
  password: string;
  role: UserRole;
  code: string;
}

const roles = [
  { label: 'Employee', value: UserRole.EMPLOYEE },
  { label: 'Administrator', value: UserRole.ADMINISTRATOR },
  { label: 'Manager', value: UserRole.MANAGER },
];

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1).required('First name is required'),
  surname: Yup.string().min(1).required('Surname is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

export const AddEmployeeModal: FC<AddEmployeeModalProps> = ({
  onHide,
  show,
  onSave,
}) => {
  const { handleSubmit, handleChange, errors, resetForm } =
    useFormik<AddEmployeeFormValues>({
      initialValues: {
        name: '',
        surname: '',
        password: '',
        email: '',
        role: roles[0].value,
        code: '',
      },
      onSubmit: (values) => {
        handleSubmitForm(values);
      },
      validationSchema,
    });

  const handleSubmitForm = async (values: CreateEmployeeDto) => {
    onSave(values);
    resetForm();
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Employee
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="employeeName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="First Name"
              required
              onChange={handleChange}
            />
            <Form.Text className="error">{errors.name}</Form.Text>
          </Form.Group>
          <Form.Group controlId="employeeSurname">
            <Form.Label>Surname</Form.Label>
            <Form.Control
              type="text"
              name="surname"
              placeholder="Surname"
              required
              onChange={handleChange}
            />
            <Form.Text className="error">{errors.surname}</Form.Text>
          </Form.Group>
          <Form.Group controlId="employeeEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Email... "
              required
              onChange={handleChange}
            />
            <Form.Text className="error">{errors.email}</Form.Text>
          </Form.Group>
          <Form.Group controlId="employeePassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password *"
              required
              onChange={handleChange}
            />
            <Form.Text className="error"> {errors.password} </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label
              className="my-1 mr-2"
              htmlFor="inlineFormCustomSelectPref"
            >
              Role
            </Form.Label>
            <Form.Control
              name="role"
              placeholder="Role... *"
              required
              onChange={handleChange}
              as="select"
              className="my-1 mr-sm-2"
              id="inlineFormCustomSelectPref"
              custom
            >
              {roles.map((r) => (
                <option key={r.value} value={r.value}>
                  {r.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="code">
            <Form.Label>Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Code"
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()}>Close</Button>
        <Button
          variant="success"
          type="submit"
          onClick={(e) => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEmployeeModal;
