import axios from 'axios';
import React, { FC, useState } from 'react';
import { Row } from 'react-bootstrap';
import { MonthlyEmployeeData } from './MonthlyEmployeeDataInterface';
import { MonthlyEmployeeForm } from './MonthlyEmployeeForm';
import { MonthlyEmployeeTable } from './MonthlyEmployeeTable';

interface FormDto {
  employeeId: string;
  month: number;
  year: number;
}

export const MonthlyEmployeeReport: FC = () => {
  const [report, setReport] = useState<MonthlyEmployeeData>();

  const onFormSubmit = async ({ employeeId, year, month }: FormDto) => {
    const { data } = await axios.get<MonthlyEmployeeData>(
      `/reports/monthly-report/${employeeId}`,
      { params: { month, year } }
    );
    setReport(data);
  };

  return (
    <>
      <Row className="d-print-none">
        <MonthlyEmployeeForm onSubmitHandler={onFormSubmit} />
      </Row>
      {report && (
        <Row xs={12}>
          <div id="printTable" className="w-100">
            <MonthlyEmployeeTable report={report} />
          </div>
        </Row>
      )}
    </>
  );
};
