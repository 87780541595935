import { ProjectDto } from '@portal/client-portal-api-model';
import { useRequest } from '@portal/frontend/react';
import { sortByName } from '@portal/frontend/utils';
import Endpoint from '../enums/apiEndpoints';

export function useProjects() {
  const {
    data: projects,
    response,
    error,
    isValidating,
    mutate,
    mutateData,
  } = useRequest<ProjectDto[]>({
    url: Endpoint.PROJECT_SETTINGS,
  });

  return {
    projects: projects?.sort(sortByName),
    projectsResponse: response,
    error,
    isValidating,
    mutateProjectsResponse: mutate,
    mutateProjects: mutateData,
  };
}
