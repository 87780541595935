import React, { FC, useState } from 'react';
import { Id } from '@silevis/reactgrid';
import { Button, Modal } from 'react-bootstrap';
import { WorkItem } from '../interfaces/WorkItem';
import { ActionSpinner } from '@portal/frontend/react';

export interface ModalData {
  show: boolean;
  workItem: WorkItem;
  hasWorkItemExistingDependencies: boolean;
}

interface ConfirmationModalProps {
  onHide: () => void;
  onConfirm: (workItemId: Id) => void;
  modalData: ModalData;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  onHide,
  modalData,
  onConfirm,
}) => {
  const [removingWorkItem, setRemovingWorkItem] = useState(false);
  return (
    <Modal
      show={modalData.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {!modalData.hasWorkItemExistingDependencies && 'Are you sure?'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalData.hasWorkItemExistingDependencies ? (
          `This workitem cannot be deleted. Someone already reported the time on it.`
        ) : (
          <>
            Are you sure to delete:{' '}
            <b>{modalData.workItem?.name || 'Unnamed work item'}</b> ?
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="info" onClick={() => onHide()}>
          {modalData.hasWorkItemExistingDependencies ? 'OK' : 'Cancel'}
        </Button>
        {!modalData.hasWorkItemExistingDependencies && (
          <Button
            variant="danger"
            onClick={async () => {
              setRemovingWorkItem(true);
              await onConfirm(modalData.workItem.id);
              setRemovingWorkItem(false);
            }}
            disabled={removingWorkItem}
          >
            <ActionSpinner
              showSpinner={removingWorkItem}
              defaultText={'Delete'}
              spinnerContent={'Deleting...'}
            />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
