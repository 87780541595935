import {
  Loader,
  NavigationBar,
  NavItem,
  useCurrentUser,
} from '@portal/frontend/react';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { NAV_ITEMS } from '../utils/navigation.routes';

export const NavBar = () => {
  return (
    <NavigationBar>
      <NavItems />
    </NavigationBar>
  );
};

const NavItems = () => {
  const currentUser = useCurrentUser();
  if (!currentUser) {
    return <Loader />;
  }
  return (
    <Nav className="w-100">
      {NAV_ITEMS.filter(
        (item) => !item.excludeRoles?.includes(currentUser.role)
      ).map((item) => (
        <NavItem key={item.path} path={item.path} title={item.title} />
      ))}
    </Nav>
  );
};
