import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useScrollRestoration(scrollKey: string): void {
  const location = useLocation();

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem(scrollKey);

    if (savedScrollPosition) {
      document.getElementById(scrollKey).scrollTop =
        Number(savedScrollPosition);
    }

    return () => {
      const scrollPosition = document.getElementById(scrollKey).scrollTop;
      sessionStorage.setItem(scrollKey, String(scrollPosition));
    };
  }, [location, scrollKey]);
}
