import moment from 'moment';
import React from 'react';
import { FC } from 'react';
import { Table } from 'react-bootstrap';
import { MonthlyEmployeeData } from './MonthlyEmployeeDataInterface';

function getDaysInMonth(month, year): Date[] {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export const MonthlyEmployeeTable: FC<{ report: MonthlyEmployeeData }> = ({
  report,
}) => {
  const date = new Date(report.startDate);

  return (
    <div className="w-100">
      <h5>Okres {moment(date).format('YYYY-MM')}</h5>
      <h5>
        Pracownik: {report.name} {report.surname}, Oddział: Silevis Software
      </h5>
      <Table
        bordered
        hover
        size="sm"
        style={{ width: '100%', borderCollapse: 'collapse' }}
      >
        <thead style={{ backgroundColor: '#9FC7E7' }}>
          <tr>
            <th>Data</th>
            <th>Godz. przyjścia</th>
            <th>Godz. wyjścia</th>
            <th>Godz. pracy</th>
          </tr>
        </thead>

        <tbody>
          {getDaysInMonth(date.getMonth(), date.getFullYear()).map((date) => {
            const timespan = report.dailyWorkTimespans.find((ts) =>
              moment(ts.date).isSame(date, 'date')
            );
            return (
              <tr key={date.getTime()}>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {date.toLocaleDateString()}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {timespan?.timeIn?.slice(0, 5) ?? '-'}
                </td>
                <td style={{ wordWrap: 'break-word' }}>
                  {timespan?.timeOut?.slice(0, 5) ?? '-'}
                </td>
                <td style={{ wordWrap: 'break-word' }}>
                  {report.timetableEntries.reduce(
                    (a, b) =>
                      moment(b.date).isSame(date, 'date') ? a + b.hours : a,
                    0
                  )}
                </td>
              </tr>
            );
          })}
          <tr>
            <td style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
              Podsumowanie
            </td>
            <td style={{ whiteSpace: 'nowrap' }} />
            <td style={{ whiteSpace: 'nowrap' }} />
            <td style={{ wordWrap: 'break-word', fontWeight: 'bold' }}>
              {report.timetableEntries.reduce((a, b) => a + b.hours, 0)}
            </td>
          </tr>
          <tr>
            <td style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
              Podpis zleceniobiorcy
            </td>
            <td style={{ whiteSpace: 'nowrap' }} />
            <td style={{ whiteSpace: 'nowrap' }} />
            <td style={{ wordWrap: 'break-word', fontWeight: 'bold' }}>
              Podpis managera
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
