import moment from 'moment';
import React, { FC } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import { useMonthWorkHoursSummaryReport } from '../../../../hooks/useMonthWorkHoursSummaryReport';
import WorkHoursForm, { WorkHoursFormValues } from './components/WorkHoursForm';
import WorkHoursSummaryTable from './components/WorkHoursSummaryTable';

const WorkHoursMonthSummary: FC = () => {
  const {
    trigger,
    reportData,
    isReportValidating: isLoading,
  } = useMonthWorkHoursSummaryReport();

  const onFormSubmit = async ({ year, month }: WorkHoursFormValues) => {
    const startDate = moment([year, month - 1])
      .startOf('month')
      .toDate();
    const endDate = moment([year, month - 1])
      .endOf('month')
      .toDate();

    await trigger({ startDate, endDate });
  };

  return (
    <>
      <Row className="d-print-none">
        <WorkHoursForm onSubmitHandler={onFormSubmit} />
      </Row>

      <Row>
        {isLoading ? (
          <div className="text-center d-flex align-items-center w-100 justify-content-center my-3">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          reportData && <WorkHoursSummaryTable data={reportData} />
        )}
      </Row>
    </>
  );
};

export default WorkHoursMonthSummary;
