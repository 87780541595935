import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Axios from 'axios';
import {
  DeleteLockTimeDTO,
  LockTimeDto,
} from '@portal/client-portal-api-model';
import Endpoint from '../../enums/apiEndpoints';
import { sortByName } from '@portal/frontend/utils';
import useArray from '../../hooks/useArray';
import {
  RowCells,
  SilevisPortalRow,
} from '../../interfaces/reactgrid.interface';
import { Loader, useRequest } from '@portal/frontend/react';
import { CellChange, ReactGrid } from '@silevis/reactgrid';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { lockTimeTabColumns } from '../../consts/LockTimeTabColumns';
import { LockTimeModal } from './LockTimeModal';

const HEADER_STYLE = { background: '#007bff', color: 'white' } as const;

export const LockTimeTab = () => {
  const [employees, setEmployees] = useArray<LockTimeDto>([]);

  const [selectedEmployees, setSelectedEmployees] = useState<DeleteLockTimeDTO>(
    [],
  );

  const [lockTimeModalShow, setLockTimeModalShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { data, mutate } = useRequest<LockTimeDto[]>({
    url: Endpoint.LOCK_TIME,
  });

  const fetchLockTimeData = useCallback(() => {
    setIsLoading(true);

    if (data) {
      const sortedData = data.sort(sortByName);
      setEmployees(sortedData);
    }

    setIsLoading(false);
  }, [data, setEmployees]);

  useEffect(() => {
    fetchLockTimeData();
  }, [fetchLockTimeData]);

  const rows: SilevisPortalRow[] = useMemo(() => {
    return [
      {
        rowId: 0,
        height: 45,
        cells: [
          {
            type: 'checkbox',
            checkedText: 'Header Locked',
            checked: selectedEmployees.length === employees.length,
          },
          { type: 'header', text: 'First Name', style: HEADER_STYLE },
          { type: 'header', text: 'Surname', style: HEADER_STYLE },
          { type: 'header', text: 'Email', style: HEADER_STYLE },
          { type: 'header', text: 'Lock date', style: HEADER_STYLE },
        ],
      },
      ...employees.map<SilevisPortalRow>((employee, index) => {
        const textColor = '#000';
        const defaultDate = moment()
          .subtract(1, 'months')
          .endOf('month')
          .toDate();

        return {
          rowId: employee.employeeId,
          height: 35,
          cells: [
            {
              type: 'checkbox',
              checkedText: 'Locked',
              checked: selectedEmployees.includes(employee.employeeId),
            },
            {
              type: 'text',
              text: employee.name,
              nonEditable: true,
              style: {
                background: 'white',
                color: textColor,
              },
            },
            {
              type: 'text',
              text: employee.surname || '',
              nonEditable: true,
              style: {
                background: 'white',
                color: textColor,
              },
            },
            {
              type: 'text',
              nonEditable: true,
              text: employee.email,
              style: {
                background: 'white',
                color: textColor,
              },
            },
            {
              type: 'date',
              date: moment(employee.lockDate).toDate(),
              format: new Intl.DateTimeFormat('pl', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }),
              nonEditable: false,
              style: {
                background: moment(defaultDate).isSame(employee.lockDate, 'day')
                  ? '#d9d9d9'
                  : '#ff6969',
              },
            },
          ],
        };
      }),
    ];
  }, [employees, selectedEmployees]);

  const handleChanges = async (changes: CellChange<RowCells>[]) => {
    changes.forEach(async (change) => {
      if (change.type === 'date') {
        await Axios.post(
          `${Endpoint.LOCK_TIME}/customLockTime/${change.rowId}`,
          {
            lockDate: moment(change.newCell.date).format('YYYY-MM-DD'),
          },
        );
        mutate();
      }

      if (change.type === 'checkbox' && change.rowId === 0) {
        const isChecked = change.newCell.checked;

        if (isChecked) {
          setSelectedEmployees(
            employees.map((employee) => employee.employeeId),
          );
          return;
        } else {
          setSelectedEmployees([]);
        }
      }

      if (change.type === 'checkbox' && change.rowId !== 0) {
        const employeeId = change.rowId;
        const isChecked = change.newCell.checked;

        if (isChecked) {
          setSelectedEmployees((prevSelectedEmployees) => [
            ...prevSelectedEmployees,
            employeeId.toString(),
          ]);
          return;
        } else {
          setSelectedEmployees((prevSelectedEmployees) =>
            prevSelectedEmployees.filter((id) => id !== employeeId),
          );
        }
      }
    });
  };

  const handleConfirm = async () => {
    await Axios.patch(
      `${Endpoint.LOCK_TIME}/deleteLockTime`,
      selectedEmployees,
    );
    mutate();
    setSelectedEmployees([]);
    setLockTimeModalShow(false);
  };

  if (isLoading) {
    return <Loader fullScreen></Loader>;
  }

  return (
    <div className="d-flex align-items-center flex-column w-100 p-5">
      <div className="overflow-hidden h-100">
        <div className="d-flex justify-content-end mb-3">
          <Button
            className="ml-2"
            onClick={() => {
              setLockTimeModalShow(true);
            }}
            disabled={selectedEmployees.length <= 0}
          >
            Lock last day of previous month
          </Button>
        </div>

        <div className="d-flex h-100 pb-3">
          <div className="overflow-auto">
            <ReactGrid
              rows={rows}
              columns={lockTimeTabColumns}
              onCellsChanged={handleChanges}
            />
          </div>
        </div>

        <LockTimeModal
          show={lockTimeModalShow}
          onHide={() => {
            setLockTimeModalShow(false);
          }}
          onConfirm={async () => {
            handleConfirm();
          }}
        />
      </div>
    </div>
  );
};
