import { ProjectDto, UpdateProjectDto } from '@portal/client-portal-api-model';
import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

interface ProjectModalProps {
  onHide: () => void;
  onSave: (project: UpdateProjectDto) => Promise<void>;
  show: boolean;
  project: ProjectDto;
}

export const ProjectModal: FC<ProjectModalProps> = ({
  onHide,
  show,
  onSave,
  project,
}) => {
  const [editedProject, setEditedProject] = useState<UpdateProjectDto>({
    name: '',
  });

  useEffect(() => {
    if (project) {
      setEditedProject(project);
    }
  }, [project]);

  const handleOnHide = () => {
    onHide();
    setEditedProject({ name: '' });
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      onHide={handleOnHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {project ? 'Edit project' : 'Add project'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(event) => event.preventDefault()}>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              value={editedProject?.name}
              ref={(ref) => {
                ref?.focus();
              }}
              onChange={(e) =>
                setEditedProject({ ...editedProject, name: e.target.value })
              }
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOnHide}>Close</Button>
        <Button
          variant="success"
          onClick={async () => {
            try {
              await onSave(editedProject);
            } catch (error) {
              console.error(error);
            }
            setEditedProject({ name: '' });
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectModal;
