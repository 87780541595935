import React from 'react';
import { FC } from 'react';
import { Table } from 'react-bootstrap';
import { ReportTableProps } from './ReportTableProps.interface';
import { TableHeader } from '@portal/frontend/react';
import { formatter } from '@portal/frontend/utils';

export const PeopleProjectTable: FC<ReportTableProps> = ({
  report,
  showCosts,
  showHours,
  noBreakdown,
}) => {
  const colSpan = noBreakdown ? 1 : 3;
  return (
    <>
      {report.length === 0 && <span>This report has no items to display</span>}
      {report.map((employee) => (
        <Table
          bordered
          hover
          size="sm"
          style={{ width: '100%', borderCollapse: 'collapse' }}
          key={employee.id}
        >
          <TableHeader
            showHours={showHours}
            showCosts={showCosts}
            noBreakdown={noBreakdown}
          />
          <thead style={{ backgroundColor: '#9FC7E7' }}>
            <tr key={employee.id}>
              <th
                style={{ whiteSpace: 'nowrap', maxWidth: '1px' }}
                colSpan={colSpan}
              >{`${employee.name} ${employee.surname}`}</th>
              {showHours && (
                <th
                  className="font-weight-bold"
                  style={{
                    textAlign: 'right',
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 'normal',
                    width: '1px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {formatter.format(
                    employee.collaborators
                      .flatMap((collaborator) =>
                        collaborator.timetableEntries.map(
                          (entry) => entry.hours
                        )
                      )
                      .reduce((a, b) => a + b)
                  )}
                </th>
              )}
              {showCosts && (
                <th
                  className="font-weight-bold"
                  style={{
                    textAlign: 'right',
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 'normal',
                    width: '1px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {formatter.format(
                    employee.collaborators
                      .flatMap((collaborator) =>
                        collaborator.timetableEntries.map(
                          (entry) => entry.hours * collaborator.role.hourlyRate
                        )
                      )
                      .reduce((a, b) => a + b)
                  )}
                </th>
              )}
            </tr>
          </thead>
          {employee.collaborators.map((collaborator) => {
            return (
              <React.Fragment key={collaborator.id}>
                <thead style={{ backgroundColor: '#D2E4F3' }}>
                  <tr key={collaborator.project.id}>
                    <th
                      style={{ whiteSpace: 'nowrap', maxWidth: '1px' }}
                      colSpan={colSpan}
                    >
                      {collaborator.project.name}{' '}
                      {collaborator.project.deletedAt ? '(archived)' : ''}
                    </th>
                    {showHours && (
                      <th
                        className="font-weight-bold"
                        style={{
                          textAlign: 'right',
                          fontFamily: 'inherit',
                          fontSize: 'inherit',
                          fontWeight: 'normal',
                          width: '1px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatter.format(
                          collaborator.timetableEntries
                            .map((entry) => entry.hours)
                            .reduce((a, b) => a + b)
                        )}
                      </th>
                    )}
                    {showCosts && (
                      <th
                        className="font-weight-bold"
                        style={{
                          textAlign: 'right',
                          fontFamily: 'inherit',
                          fontSize: 'inherit',
                          fontWeight: 'normal',
                          width: '1px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatter.format(
                          collaborator.timetableEntries
                            .map(
                              (entry) =>
                                entry.hours * collaborator.role.hourlyRate
                            )
                            .reduce((a, b) => a + b)
                        )}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {!noBreakdown &&
                    collaborator.timetableEntries.map((entry) => {
                      return (
                        <tr key={entry.id}>
                          <td style={{ width: '1px', whiteSpace: 'nowrap' }}>
                            {entry.date}
                          </td>
                          <td
                            style={{
                              overflowWrap: 'break-word',
                              maxWidth: '100%',
                            }}
                          >{`#${entry.workItem.extId} ${entry.workItem.name}`}</td>
                          <td
                            style={{
                              overflowWrap: 'break-word',
                              maxWidth: '100%',
                            }}
                          >
                            {entry.description}
                          </td>
                          {showHours && (
                            <td
                              style={{
                                width: '1px',
                                whiteSpace: 'nowrap',
                                textAlign: 'right',
                              }}
                            >
                              {formatter.format(entry.hours)}
                            </td>
                          )}
                          {showCosts && (
                            <td
                              style={{
                                width: '1px',
                                whiteSpace: 'nowrap',
                                textAlign: 'right',
                              }}
                            >
                              {formatter.format(
                                entry.hours * collaborator.role.hourlyRate
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </React.Fragment>
            );
          })}
        </Table>
      ))}
    </>
  );
};
