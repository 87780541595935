import useSWRMutation from 'swr/mutation';
import Endpoint from '../enums/apiEndpoints';
import { getWorkHoursMonthSummary } from '../functions/getWorkHoursMonthSummary';

export function useMonthWorkHoursSummaryReport() {
  const { trigger, data, error, isMutating } = useSWRMutation(
    Endpoint.MONTH_WORK_HOURS_SUMMARY_REPORT,
    getWorkHoursMonthSummary,
  );

  return {
    reportData: data,
    error,
    trigger,
    isReportValidating: isMutating,
  };
}
