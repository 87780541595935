import { CellChange, ReactGrid, Id } from '@silevis/reactgrid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Button } from 'react-bootstrap';
import Axios, { AxiosResponse } from 'axios';
import Endpoint from '../../enums/apiEndpoints';
import AddEmployeeModal from './AddEmployeeModal';
import {
  ButtonCellTemplate,
  CustomDropdownCellTemplate,
  Loader,
  ToggleSwitch,
  useCurrentUser,
  useModal,
  UserRole,
} from '@portal/frontend/react';
import {
  RowCells,
  SilevisPortalRow,
} from '../../interfaces/reactgrid.interface';
import {
  CreateEmployeeDto,
  EmployeeDto,
} from '@portal/client-portal-api-model';
import useArray from '../../hooks/useArray';
import { getEmployeeTabColumns } from '../../functions';
import ChangePasswordModal from './ChangePasswordModal';
import { sortByName } from '@portal/frontend/utils';

const ROLES = [
  { label: 'Employee', value: UserRole.EMPLOYEE },
  { label: 'Administrator', value: UserRole.ADMINISTRATOR },
  { label: 'Manager', value: UserRole.MANAGER },
] as const;

const HEADER_STYLE = { background: '#007bff', color: 'white' } as const;

export const EmployeeTab = () => {
  const [visible, open, close] = useModal();
  const [changePasswordVisible, openChangePassword, closeChangePassword] =
    useModal();
  const [employees, setEmployees, add, update] = useArray<EmployeeDto>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeDto | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showDeactivated, setShowDeactivated] = useState(true);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const { data } = await Axios.get<null, AxiosResponse<EmployeeDto[]>>(
      `${Endpoint.EMPLOYEES}`,
    );
    setIsLoading(false);
    setEmployees(data.sort(sortByName));
  }, [setEmployees]);

  const currentUser = useCurrentUser();

  useEffect(() => {
    fetchData().catch();
  }, [fetchData]);

  const rows: SilevisPortalRow[] = useMemo(() => {
    return [
      {
        rowId: 0,
        height: 45,
        cells: [
          { type: 'header', text: 'First Name', style: HEADER_STYLE },
          { type: 'header', text: 'Surname', style: HEADER_STYLE },
          { type: 'header', text: 'Code', style: HEADER_STYLE },
          { type: 'header', text: 'Email', style: HEADER_STYLE },
          { type: 'header', text: 'System role', style: HEADER_STYLE },
          { type: 'header', text: 'Status', style: HEADER_STYLE },
          { type: 'header', text: 'Action', style: HEADER_STYLE },
          { type: 'header', text: '', style: HEADER_STYLE },
        ],
      },
      ...employees
        .filter((employee) => (showDeactivated ? true : !employee.deletedAt))
        .map<SilevisPortalRow>((employee) => {
          const textColor = employee.deletedAt ? '#aaa' : '#000';
          return {
            rowId: employee.id,
            height: 35,
            cells: [
              {
                type: 'text',
                text: employee.name,
                style: {
                  background: 'white',
                  color: textColor,
                },
              },
              {
                type: 'text',
                text: employee.surname || '',
                style: {
                  background: 'white',
                  color: textColor,
                },
              },
              {
                type: 'text',
                text: employee.code || '',
                style: {
                  background: 'white',
                  color: textColor,
                },
              },
              {
                type: 'text',
                nonEditable: true,
                text: employee.email,
                style: {
                  background: 'white',
                  color: textColor,
                },
              },
              {
                type: 'customDropdown',
                values: ROLES.map((r) => r.label),
                text: ROLES.find((r) => r.value === employee.role).label,
                style: { background: 'white' },
                disabled: employee.deletedAt ? true : false,
              },
              {
                type: 'text',
                nonEditable: true,
                text: employee.deletedAt
                  ? 'deactivated'
                  : employee.isUsedInSystem
                    ? 'active'
                    : 'new',
                style: {
                  background: 'white',
                  color: textColor,
                },
              },
              {
                type: 'button',
                value: employee.deletedAt
                  ? 'Restore'
                  : employee.isUsedInSystem
                    ? 'Deactivate'
                    : 'Delete',
                text: employee.deletedAt
                  ? 'Restore'
                  : employee.isUsedInSystem
                    ? 'Deactivate'
                    : 'Delete',
                context: employee.deletedAt
                  ? 'restore'
                  : employee.isUsedInSystem
                    ? 'deactivate'
                    : 'delete',
                style: { background: 'white' },
                variant: employee.deletedAt
                  ? 'link'
                  : employee.isUsedInSystem
                    ? 'secondary'
                    : 'danger',
                disabled: currentUser.id === employee.id,
              },
              {
                type: 'button',
                value: 'Change password',
                text: 'Change password',
                variant: 'info',
                style: {
                  background: 'white',
                },
                disabled: employee.deletedAt ? true : false,
              },
            ],
          };
        }),
    ];
  }, [currentUser.id, employees, showDeactivated]);

  const handleAddEmployee = async (employee: CreateEmployeeDto) => {
    const { data } = await Axios.post(Endpoint.EMPLOYEES, employee);
    close();
    add(data);
  };

  const handleChangePassword = async (formValues) => {
    await Axios.post<EmployeeDto, AxiosResponse<EmployeeDto>>(
      `${Endpoint.EMPLOYEES}/${selectedEmployee.id}`,
      {
        password: formValues.password,
      },
    );
    closeChangePassword();
  };

  const handleDeactivateEmployee = async (employeeId: Id) => {
    await Axios.patch(`${Endpoint.EMPLOYEES}/${employeeId}/deactivate`);
    await fetchData();
  };

  const handleUpdateEmployee = async (employee: EmployeeDto) => {
    const { data } = await Axios.post(`${Endpoint.EMPLOYEES}/${employee.id}`, {
      role: employee.role,
      code: employee.code,
      name: employee.name,
      surname: employee.surname,
    });
    update(data);
  };

  const handleRestoreEmployee = async (employeeId: Id) => {
    await Axios.patch(`${Endpoint.EMPLOYEES}/${employeeId}/restore`);
    await fetchData();
  };

  const handleRemoveEmployee = async (employeeId: Id) => {
    await Axios.delete(`${Endpoint.EMPLOYEES}/${employeeId}`);
    await fetchData();
  };

  const handleChanges = (changes: CellChange<RowCells>[]) => {
    changes.forEach(async (change) => {
      const updatedEmployee = employees.find(
        (employee) => employee.id === change.rowId,
      );

      if (
        change.columnId === 'action' &&
        change.type === 'button' &&
        change.newCell.context === 'deactivate'
      ) {
        handleDeactivateEmployee(change.rowId);
      }

      if (
        change.columnId === 'action' &&
        change.type === 'button' &&
        change.newCell.context === 'restore'
      ) {
        handleRestoreEmployee(change.rowId);
      }

      if (
        change.columnId === 'action' &&
        change.type === 'button' &&
        change.newCell.context === 'delete'
      ) {
        handleRemoveEmployee(change.rowId);
      }

      if (change.columnId === 'changePassword') {
        setSelectedEmployee(updatedEmployee);
        openChangePassword();
      }

      if (change.columnId === 'code' && change.type === 'text') {
        handleUpdateEmployee({ ...updatedEmployee, code: change.newCell.text });
      }

      if (change.columnId === 'role' && change.type === 'customDropdown') {
        const foundRole = ROLES.find(
          (role) => role.label === change.newCell.text,
        ).value;

        handleUpdateEmployee({ ...updatedEmployee, role: foundRole });
      }

      const nameAndSurnameRegex = /^[A-Za-zÀ-ȕ\s-]+$/;

      if (change.columnId === 'name' && change.type === 'text') {
        const textIsInCorrect = !change.newCell.text.match(nameAndSurnameRegex);

        if (textIsInCorrect) {
          return;
        }

        handleUpdateEmployee({ ...updatedEmployee, name: change.newCell.text });
      }

      if (change.columnId === 'surname' && change.type === 'text') {
        const textIsInCorrect = !change.newCell.text.match(nameAndSurnameRegex);

        if (textIsInCorrect) {
          return;
        }

        handleUpdateEmployee({
          ...updatedEmployee,
          surname: change.newCell.text,
        });
      }
    });
  };

  if (isLoading) {
    return <Loader fullScreen></Loader>;
  }

  return (
    <div className="p-5 overflow-hidden h-100">
      <div className="pb-3 d-flex">
        <Col xs={6}>
          <div>
            <ToggleSwitch
              name="Show deactivated"
              onChange={() => setShowDeactivated(!showDeactivated)}
              checked={showDeactivated}
            />
          </div>
        </Col>

        <Col xs={6}>
          <div className="d-flex justify-content-end">
            <Button size="sm" className="mr-3" onClick={() => open()}>
              Add new{' '}
            </Button>
          </div>
        </Col>
      </div>

      <div className="d-flex h-100 pb-3">
        <div className="overflow-auto">
          <ReactGrid
            rows={rows}
            columns={getEmployeeTabColumns()}
            stickyTopRows={1}
            onCellsChanged={handleChanges}
            customCellTemplates={{
              button: new ButtonCellTemplate(),
              customDropdown: new CustomDropdownCellTemplate(),
            }}
          />
        </div>

        <AddEmployeeModal
          show={visible}
          onHide={() => close()}
          onSave={(employee) => handleAddEmployee(employee)}
        />
        <ChangePasswordModal
          show={changePasswordVisible}
          onHide={() => closeChangePassword()}
          onSave={(formValues) => handleChangePassword(formValues)}
          employee={selectedEmployee}
        />
      </div>
    </div>
  );
};
