import { CellChange, ReactGrid, Id } from '@silevis/reactgrid';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Role } from '../../interfaces/Role';
import Endpoint from '../../enums/apiEndpoints';
import Axios, { AxiosResponse } from 'axios';
import { ButtonCellTemplate } from '@portal/frontend/react';
import { CreateRoleDto, UpdateRoleDto } from '@portal/client-portal-api-model';
import {
  RowCells,
  SilevisPortalRow,
} from '../../interfaces/reactgrid.interface';
import useArray from '../../hooks/useArray';
import { Loader } from '@portal/frontend/react';
import { getRolesTabColumns } from '../../functions';

interface RolesTabProps {
  projectId: string;
}

export const RolesTab: FC<RolesTabProps> = (props) => {
  const { projectId } = props;
  const [roles, setRoles, add, update] = useArray<Role>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    if (!projectId) {
      return;
    }
    setIsLoading(true);
    const { data } = await Axios.get(`${Endpoint.PROJECT_ROLES}/${projectId}`);
    setRoles(data);
    setIsLoading(false);
  }, [projectId, setRoles]);

  useEffect(() => {
    fetchData().catch();
  }, [fetchData]);

  const rows: SilevisPortalRow[] = useMemo(() => {
    return [
      {
        rowId: 0,
        height: 45,
        cells: [
          {
            type: 'header',
            text: 'Name',
            style: { background: '#007bff', color: 'white' },
          },
          {
            type: 'header',
            text: 'Hourly rate',
            style: { background: '#007bff', color: 'white' },
          },
          {
            type: 'header',
            text: ' - ',
            style: { background: '#007bff', color: 'white' },
          },
        ],
      },
      ...roles.map<SilevisPortalRow>((role) => {
        const textColor = role.deletedAt ? '#aaa' : '#000';
        return {
          rowId: role.id,
          height: 35,
          cells: [
            {
              type: 'text',
              text: role.name,
              nonEditable: !!role.deletedAt,
              style: { background: 'white', color: textColor },
            },
            {
              type: 'number',
              value: role.hourlyRate,
              nonEditable: !!role.deletedAt,
              style: { background: 'white', color: textColor },
            },
            {
              type: 'button',
              text: role.deletedAt
                ? 'Restore'
                : role.isUsedInSystem
                  ? 'Deactivate'
                  : 'Delete',
              value: role.deletedAt
                ? 'Restore'
                : role.isUsedInSystem
                  ? 'Deactivate'
                  : 'Delete',
              variant: role.deletedAt
                ? 'link'
                : role.isUsedInSystem
                  ? 'secondary'
                  : 'danger',
              context: role.deletedAt
                ? 'restore'
                : role.isUsedInSystem
                  ? 'deactivate'
                  : 'delete',
              style: { background: 'white' },
            },
          ],
        };
      }),
    ];
  }, [roles]);

  const handleAddRole = async () => {
    const newRole: CreateRoleDto = {
      name: 'New Role',
      hourlyRate: 0,
      projectId,
    };
    const { data } = await Axios.post(Endpoint.ROLES, newRole);
    add(data);
  };

  const handleUpdateRole = async (role: Role) => {
    await Axios.post<UpdateRoleDto, AxiosResponse<Role>>(
      `${Endpoint.ROLES}/${role.id}`,
      {
        id: role.id,
        name: role.name,
        hourlyRate: role.hourlyRate,
      },
    );
    await fetchData();
  };

  const handleDeactivateRole = async (role: Role) => {
    await Axios.post<UpdateRoleDto, AxiosResponse<Role>>(
      `${Endpoint.ROLES}/${role.id}`,
      {
        id: role.id,
        name: role.name,
        hourlyRate: role.hourlyRate,
        deletedAt: new Date(),
      },
    );
    await fetchData();
  };

  const handleDeleteRole = async (roleId: Id) => {
    await Axios.delete(`${Endpoint.ROLES}/${roleId}`);
    await fetchData();
  };

  const handleRestoreRole = async (role: Role) => {
    const updatedRole: UpdateRoleDto = {
      id: role.id,
      name: role.name,
      hourlyRate: role.hourlyRate,
      deletedAt: null,
    };
    const { data } = await Axios.post(
      `${Endpoint.ROLES}/${updatedRole.id}`,
      updatedRole,
    );
    update(data);
  };

  const handleChanges = (changes: CellChange<RowCells>[]) => {
    changes.forEach((change) => {
      const updatedRole = roles.find((role) => role.id === change.rowId);
      updatedRole.projectId = projectId;
      if (change.newCell.type === 'text') {
        updatedRole.name = change.newCell.text;
        handleUpdateRole(updatedRole);
      }
      if (change.newCell.type === 'number') {
        updatedRole.hourlyRate = change.newCell.value;
        handleUpdateRole(updatedRole);
      }
      if (change.columnId === 'action' && change.type === 'button') {
        if (change.newCell.context === 'delete') {
          handleDeleteRole(change.rowId);
        }
        if (change.newCell.context === 'restore') {
          handleRestoreRole(updatedRole);
        }
        if (change.newCell.context === 'deactivate') {
          handleDeactivateRole(updatedRole);
        }
      }
    });
  };

  if (isLoading) {
    return <Loader fullScreen></Loader>;
  }

  return (
    <div className="h-100 d-flex flex-column">
      <div className="overflow-hidden d-flex">
        <div className="overflow-auto">
          <ReactGrid
            rows={rows}
            columns={getRolesTabColumns()}
            stickyTopRows={1}
            onCellsChanged={handleChanges}
            customCellTemplates={{
              button: new ButtonCellTemplate(),
            }}
          />
        </div>
      </div>

      <div className="d-flex pt-3">
        <Button
          style={{ width: '80px' }}
          size="sm"
          className="mr-3"
          onClick={() => handleAddRole()}
          disabled={projectId ? false : true}
        >
          Add new{' '}
        </Button>
      </div>
    </div>
  );
};

export default RolesTab;
