import React from 'react';
import { Outlet } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import ReportsSidebar from '../components/ReportsSidebar';

export const ReportsView = () => {
  return (
    <>
      <Col md={2} xs={12} className="p-0 d-print-none">
        <ReportsSidebar />
      </Col>

      <Col md={10} xs={12} className="p-5 bg-light overflow-auto">
        <Outlet />
      </Col>
    </>
  );
};

export default ReportsView;
