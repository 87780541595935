export enum ColumnsIds {
  NAME = 'name',
  EXT_ID = 'extId',
  BUDGET_LEFT = 'budgetLeft',
  ACTUAL_PRICE = 'actualPrice',
  PRICE = 'price',
  STATUS = 'status',
  PLANNED_HOURS = 'plannedHours',
  ACTUAL_HOURS = 'actualHours',
  PROGRESS = 'progress',
  START_DATE = 'startDate',
  DEADLINE = 'deadLine',
  ARCHIVE = 'archive',
}
