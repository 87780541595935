import { useFormik } from 'formik';
import React, { FC } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { BankHoliday } from '../../interfaces/BankHoliday.interface';
import DatePicker from 'react-date-picker';

interface AddBankHolidayModalProps {
  onHide: () => void;
  onSave: (bankHoliday: BankHoliday) => void;
  show: boolean;
  year?: number;
}

interface AddBankHolidayModalValues {
  name: string;
  description?: string;
  date: Date;
  isPeriodic: boolean;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1).required('Name is required'),
  date: Yup.date().required('Date is required'),
});

export const AddBankHolidayModal: FC<AddBankHolidayModalProps> = ({
  onHide,
  show,
  onSave,
  year,
}) => {
  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const initialDate = new Date(
    year ? year : currentYear,
    currentMonth,
    currentDay,
  );
  const {
    handleSubmit,
    handleChange,
    errors,
    isValid,
    dirty,
    values,
    setFieldValue,
  } = useFormik<AddBankHolidayModalValues>({
    initialValues: {
      name: '',
      description: '',
      date: initialDate,
      isPeriodic: false,
    },
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
    validationSchema,
  });

  const handleSubmitForm = async (values: BankHoliday) => {
    onSave(values);
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Bank Holiday
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group controlId="holidayName">
            <Form.Label>Name*</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Name"
              required
              onChange={handleChange}
            />
            <Form.Text className="error">{errors.name}</Form.Text>
          </Form.Group>

          <Form.Group controlId="holidayDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              name="description"
              placeholder="Description"
              onChange={handleChange}
            />
            <Form.Text className="error">{errors.description}</Form.Text>
          </Form.Group>

          <Form.Group controlId="date">
            <Form.Label>Date</Form.Label>
            <DatePicker
              className="d-block"
              onChange={(date) => setFieldValue('date', date)}
              value={values.date}
              format={values.isPeriodic ? 'dd-MM' : 'dd-MM-yyyy'}
              required
            />
            <Form.Text className="error">{errors.date}</Form.Text>
          </Form.Group>

          <Form.Group controlId="isPeriodic">
            <Form.Check
              type="checkbox"
              label="Periodic"
              name="isPeriodic"
              onChange={handleChange}
            />
            <Form.Text className="error">{errors.isPeriodic}</Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => onHide()}>Close</Button>
        <Button
          variant="success"
          type="submit"
          disabled={!(isValid && dirty)}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBankHolidayModal;
