import useSWRMutation from 'swr/mutation';
import Endpoint from '../enums/apiEndpoints';
import { updateBankHolidays } from '../functions/updateBankHoliday';

export function useUpdateBankHoliday() {
  const { trigger, isMutating } = useSWRMutation(
    Endpoint.BANK_HOLIDAYS,
    updateBankHolidays,
  );

  return {
    trigger,
    isMutating,
  };
}
