import { ColumnsIds } from '../../enums/ColumnsIds';
import { Role } from '../../interfaces/Role';
import { WorkItem } from '../../interfaces/WorkItem';
import { getCells } from './getCells';
import { getHeaders } from './getHeaders';
import {
  RowCells,
  SilevisPortalRow,
} from '../../interfaces/reactgrid.interface';
import { getExtendedTextCell } from '@portal/frontend/react';

export const getRows = (
  workItems: WorkItem[],
  roles: Role[],
  hiddenColIds: ColumnsIds[] = []
): SilevisPortalRow[] => {
  const cellsFilter = (cell: RowCells) =>
    !hiddenColIds.some((i) => cell.columnId.includes(i));
  return [
    {
      rowId: 'header',
      height: 40,
      cells: getHeaders(roles).filter(cellsFilter),
    },
    ...workItems.map<SilevisPortalRow>((workItem) => ({
      reorderable: true,
      height: 30,
      rowId: workItem.id,
      cells: [
        getExtendedTextCell(workItem.name, { background: 'white' }, ''),
        ...getCells(workItem, roles).filter(cellsFilter),
      ],
    })),
  ];
};
