import axios from 'axios';
import { BankHolidayDto } from '../interfaces/BankHoliday.interface';

export async function getBankHolidays(
  url: string,
  {
    arg,
  }: {
    arg: {
      year: number;
    };
  },
): Promise<BankHolidayDto[]> {
  return axios.get(`${url}/${arg.year}`).then((response) => response?.data);
}
