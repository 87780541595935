import React, { FC } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { EmployeeDto } from '@portal/client-portal-api-model';

interface ChangePasswordModalProps {
  onHide: () => void;
  onSave: (formValues: ChangePasswordFormValues) => void;
  show: boolean;
  employee: EmployeeDto;
}

interface ChangePasswordFormValues {
  password: string;
  password2: string;
}

const validationSchema = Yup.object().shape<ChangePasswordFormValues>({
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  password2: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  ),
});

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  onHide,
  show,
  onSave,
  employee,
}) => {
  const { handleSubmit, handleChange, errors } =
    useFormik<ChangePasswordFormValues>({
      initialValues: {
        password: '',
        password2: '',
      },
      onSubmit: (values) => {
        handleSubmitForm(values);
      },
      validationSchema,
    });

  if (!employee) {
    return null;
  }

  const handleSubmitForm = async (values: ChangePasswordFormValues) => {
    onSave(values);
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Change password for: {employee.name} {employee.surname}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="employeePassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password *"
              required
              onChange={handleChange}
            />
            <Form.Text className="error"> {errors.password} </Form.Text>
          </Form.Group>
          <Form.Group controlId="employeePassword2">
            <Form.Label>Confirm password</Form.Label>
            <Form.Control
              type="password"
              name="password2"
              placeholder="Confirm password"
              required
              onChange={handleChange}
            />
            <Form.Text className="error"> {errors.password2} </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()}>Close</Button>
        <Button
          variant="success"
          type="submit"
          onClick={(e) => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
