import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Axios from 'axios';
import { ReportsFiltersDto } from '@portal/client-portal-api-model';
import { ReportsForm } from './ReportsForm';
import Endpoint from '../../enums/apiEndpoints';
import { PeopleProjectTable } from './PeopleProjectTable';
import { ReportType } from '../../enums/ReportType.enum';
import { ProjectWorkitemsTable } from './/ProjectWorkitemsTable';
import { ProjectPeopleTable } from './ProjectPeopleTable';
import { ProjectPeopleTotalsTable } from './ProjectPeopleTotalsTable';
import { ToggleSwitch } from '@portal/frontend/react';
import {
  flattenPeopleToProjectReport,
  flattenProjectToPeopleReport,
  flattenProjectToWorkItemsReport,
} from '../../utils/flattenTimesheetEntries';
import { packAndDownloadCSV } from '../../utils/packAndDownloadCSV';

interface ReportsFormPeopleProjectsProps {
  reportType: ReportType;
}

export const Report: FC<ReportsFormPeopleProjectsProps> = ({ reportType }) => {
  const [showHours, setShowHours] = useState<boolean>(true);
  const [showCosts, setShowCosts] = useState<boolean>(true);
  const [noBreakdown, setNoBreakdown] = useState(false);
  const [report, setReport] = useState<any[] | null>(null);
  const [csvFileName, setCsvFileName] = useState<string>('');

  const lastReportType = useRef<ReportType>();

  /**
   * This hook is used for reseting report - old report is applied to new (uncompatible) table and it causes errors
   */
  useEffect(() => {
    lastReportType.current = reportType;
    setReport(null);
  }, [reportType]);

  const handleOnChangeShowHours = async (show: boolean) => {
    setShowHours(show);
  };

  const handleOnChangeShowCosts = async (show: boolean) => {
    setShowCosts(show);
  };

  const handleOnChangeNoBreakdown = () => {
    setNoBreakdown((noBreakdown) => !noBreakdown);
  };

  const getReports = useCallback(
    async (values: ReportsFiltersDto) => {
      const { data } = await Axios.post(
        `${Endpoint.REPORTS}/${reportType}`,
        values,
      );
      setReport(data);
    },
    [reportType],
  );

  let ReportTableComponent = null;
  let flattenEntriesFunction = null;
  switch (reportType) {
    case ReportType.PROJECT_WORKITEMS:
      ReportTableComponent = ProjectWorkitemsTable;
      flattenEntriesFunction = flattenProjectToWorkItemsReport;
      break;
    case ReportType.PEOPLE_PROJECT:
      ReportTableComponent = PeopleProjectTable;
      flattenEntriesFunction = flattenPeopleToProjectReport;
      break;
    case ReportType.PROJECT_PEOPLE:
      ReportTableComponent = ProjectPeopleTable;
      flattenEntriesFunction = flattenProjectToPeopleReport;
      break;
    case ReportType.PROJECT_PEOPLE_TOTALS:
      ReportTableComponent = ProjectPeopleTotalsTable;
      break;
    default:
      break;
  }

  const exportToCSV = () => {
    if (!flattenEntriesFunction) return;

    const data = flattenEntriesFunction(report);
    if (data.length === 0) return;

    const headers = Object.keys(data[0]);
    const indexOfOutcome = headers.indexOf('entryDescription');
    const indexOfEntryHours = headers.indexOf('entryHours');

    const formatWorkingHours = new Intl.NumberFormat('pl-PL', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    const DELIMITER = ';';

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      headers.join(DELIMITER) +
      '\n' +
      data
        .map((row) =>
          Object.values(row)
            .map((value, index) => {
              let stringValue = String(value).replace(/\n/g, ' ');

              if (
                index === indexOfEntryHours &&
                !isNaN(parseFloat(stringValue))
              ) {
                stringValue = formatWorkingHours.format(
                  parseFloat(stringValue),
                );
              }

              if (index === indexOfOutcome && value !== null) {
                stringValue = stringValue.replace(/"/g, "'");
                stringValue = stringValue.replace(/;/g, ',');

                stringValue = `"${stringValue}"`;
              }

              return stringValue;
            })
            .join(DELIMITER),
        )
        .join('\n');

    packAndDownloadCSV(csvContent, csvFileName);
  };

  return (
    <>
      <Row className="d-print-none">
        <ReportsForm
          onSubmitHandler={async (values, csvFileName) => {
            setCsvFileName(csvFileName);
            await getReports(values);
          }}
        />
      </Row>
      <Row className="mb-3 d-print-none">
        <Col md="auto" className="d-flex align-items-center">
          <ToggleSwitch
            name="Show hours"
            onChange={handleOnChangeShowHours}
            checked={showHours}
          />
        </Col>
        <Col md="auto" className="d-flex align-items-center">
          <ToggleSwitch
            name="Show costs"
            onChange={handleOnChangeShowCosts}
            checked={showCosts}
          />
        </Col>
        <Col md="auto" className="d-flex align-items-center">
          <ToggleSwitch
            name="No breakdown"
            onChange={handleOnChangeNoBreakdown}
            checked={noBreakdown}
            disabled={reportType === ReportType.PROJECT_PEOPLE_TOTALS}
          />
        </Col>
        <Col md="auto">
          <Button onClick={() => window.print()} variant="outline-primary">
            Print report
          </Button>
        </Col>
        {flattenEntriesFunction && (
          <Col md="auto">
            <Button disabled={!report} onClick={() => exportToCSV()}>
              Export to CSV
            </Button>
          </Col>
        )}
      </Row>
      <Row xs={12}>
        <div id="printTable" className="w-100">
          {!report || lastReportType.current !== reportType ? (
            'No report to display'
          ) : (
            <ReportTableComponent
              report={report}
              showCosts={showCosts}
              showHours={showHours}
              noBreakdown={noBreakdown}
            />
          )}
        </div>
      </Row>
    </>
  );
};
