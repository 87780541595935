export const packAndDownloadCSV = (content: string, fileName: string) => {
  const encodedUri = encodeURI(
    content
      .split('')
      .filter((char) => char !== '#')
      .join('')
  );
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
};
