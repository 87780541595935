import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  LoginView,
  NotFound,
  PrivateRoute,
  ProfileView,
  UserRole,
  ViewGuard,
} from '@portal/frontend/react';
import { AppRoutes } from '@portal/frontend/utils';
import { Container } from 'react-bootstrap';
import { ReportType } from '../enums/ReportType.enum';
import ReportsView from '../views/ReportsView';
import { EmployeeTab } from './admin-page/EmployeeTab';
import { ProjectSettings } from './admin-page/ProjectSettings';
import { MonthlyEmployeeReport } from './Reports/MonthlyEmployeeReport/MonthlyEmployeeReport';
import { Report } from './Reports/Report';
import HomePage from '../views/HomePage';
import BankHolidayView from '../views/BankHolidayView';
import EmployeeWorklogsView from '../views/EmployeeWorklogsView';
import WorkHoursMonthSummary from './Reports/MonthlyEmployeeReport/WorkHoursMonthSummary/WorkHoursMonthSummary';
import { LockTimeTab } from './admin-page/LockTimeTab';

const Routing = () => {
  return (
    <Routes>
      <Route path={AppRoutes.SIGNIN} element={<LoginView />} />

      <Route
        path={AppRoutes.HOME}
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      >
        <Route
          path={AppRoutes.HOME}
          element={<Navigate to={AppRoutes.PROJECT_SETTINGS} />}
        />

        <Route
          path={AppRoutes.PROJECT_SETTINGS}
          element={<ProjectSettings />}
        />

        <Route
          path={AppRoutes.EMPLOYEES}
          element={
            <ViewGuard allowedRoles={[UserRole.ADMINISTRATOR]}>
              <EmployeeTab />
            </ViewGuard>
          }
        />

        <Route path={AppRoutes.BANK_HOLIDAYS} element={<BankHolidayView />} />

        <Route
          path={AppRoutes.LOCK_TIMES}
          element={
            <ViewGuard
              allowedRoles={[UserRole.ADMINISTRATOR, UserRole.MANAGER]}
            >
              <LockTimeTab />
            </ViewGuard>
          }
        />

        <Route
          path={AppRoutes.PROFILE}
          element={
            <Container>
              <ProfileView />
            </Container>
          }
        />

        <Route path={AppRoutes.REPORTS} element={<ReportsView />}>
          <Route index element={<Navigate replace to="project/workitems" />} />

          <Route
            path="project/workitems"
            element={<Report reportType={ReportType.PROJECT_WORKITEMS} />}
          />

          <Route
            path="project/people"
            element={<Report reportType={ReportType.PROJECT_PEOPLE} />}
          />

          <Route
            path="project/peopleTotals"
            element={<Report reportType={ReportType.PROJECT_PEOPLE_TOTALS} />}
          />

          <Route
            path="people/project"
            element={<Report reportType={ReportType.PEOPLE_PROJECT} />}
          />

          <Route
            path="monthlyEmployeeReport"
            element={<MonthlyEmployeeReport />}
          />

          <Route
            path="workHoursMonthSummary"
            element={<WorkHoursMonthSummary />}
          />
        </Route>

        <Route
          path={AppRoutes.EMPLOYEE_WORKLOGS}
          element={
            <ViewGuard allowedRoles={[UserRole.ADMINISTRATOR]}>
              <EmployeeWorklogsView />
            </ViewGuard>
          }
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routing;
