import React, { FC } from 'react';
import { EmployeeDto } from '@portal/client-portal-api-model';
import { Button, Form, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Role } from '../../interfaces/Role';

interface AddCollaboratorModalProps {
  onHide: () => void;
  onSave: (values: AddCollaboratorFormValues) => Promise<void>;
  show: boolean;
  roles?: Role[];
  employees?: EmployeeDto[];
}

export interface AddCollaboratorFormValues {
  roleId?: string;
  employeeId?: string;
}

const validationSchema = Yup.object().shape<AddCollaboratorFormValues>({
  employeeId: Yup.string().uuid().required('Employee is required'),
  roleId: Yup.string().uuid().required('Role is required'),
});

export const AddCollaboratorModal: FC<AddCollaboratorModalProps> = ({
  onHide,
  show,
  onSave,
  employees,
  roles,
}) => {
  const { handleSubmit, handleChange, errors } =
    useFormik<AddCollaboratorFormValues>({
      initialValues: {},
      onSubmit: (values: AddCollaboratorFormValues) => {
        onSave(values);
      },
      validationSchema,
    });

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add collaborator
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="employee">
            <Form.Label>Select employee</Form.Label>
            <Form.Control
              as="select"
              name={'employeeId'}
              required
              onChange={handleChange}
            >
              <option value={null}></option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.name} {employee.surname}
                </option>
              ))}
            </Form.Control>
            <Form.Text className="error">{errors.employeeId}</Form.Text>
          </Form.Group>
          <Form.Group controlId="role">
            <Form.Label>Select role</Form.Label>
            <Form.Control
              as="select"
              name={'roleId'}
              defaultValue={null}
              required
              onChange={handleChange}
            >
              <option value={null}></option>
              {roles.map((r) => (
                <option key={r.id} value={r.id}>
                  {r.name}
                </option>
              ))}
            </Form.Control>
            <Form.Text className="error">{errors.roleId}</Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()}>Close</Button>
        <Button
          variant="success"
          type="submit"
          onClick={(e) => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCollaboratorModal;
