type ReportDomain =
  | 'project'
  | 'people'
  | 'Worklog check'
  | 'Monthly employee report';

type ReportBreakdown =
  | 'workitems'
  | 'people'
  | 'people totals'
  | 'project'
  | '';

interface ReportsRoute {
  domain: ReportDomain;
  breakdown: ReportBreakdown;
  route: string;
  showChevronIcon: boolean;
}

export const reportsRoutes: ReportsRoute[] = [
  {
    domain: 'project',
    breakdown: 'workitems',
    route: 'project/workitems',
    showChevronIcon: true,
  },
  {
    domain: 'project',
    breakdown: 'people',
    route: 'project/people',
    showChevronIcon: true,
  },
  {
    domain: 'project',
    breakdown: 'people totals',
    route: 'project/peopleTotals',
    showChevronIcon: true,
  },
  {
    domain: 'people',
    breakdown: 'project',
    route: 'people/project',
    showChevronIcon: true,
  },
  {
    domain: 'Monthly employee report',
    breakdown: '',
    route: 'monthlyEmployeeReport',
    showChevronIcon: false,
  },
  {
    domain: 'Worklog check',
    breakdown: '',
    route: 'workHoursMonthSummary',
    showChevronIcon: false,
  },
];
