import { ActionSpinner } from '@portal/frontend/react';
import React, { FC, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BankHolidayDto } from '../../interfaces/BankHoliday.interface';

interface RemoveBankHolidayModalProps {
  onHide: () => void;
  onConfirm: () => Promise<void>;
  show: boolean;
  bankHoliday: BankHolidayDto;
}

export const RemoveBankHolidayModal: FC<RemoveBankHolidayModalProps> = ({
  onHide,
  show,
  onConfirm,
  bankHoliday,
}) => {
  const [isRemovingBankHoliday, setIsRemovingBankHoliday] = useState(false);

  const handleOnHide = () => {
    onHide();
    setIsRemovingBankHoliday(false);
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      onHide={handleOnHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          You are going to remove "{bankHoliday.name}" holiday.
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>Are you sure to proceed?</Modal.Body>

      <Modal.Footer>
        <Button onClick={handleOnHide} variant="outline-primary">
          No
        </Button>

        <Button
          variant="danger"
          disabled={isRemovingBankHoliday}
          onClick={async () => {
            setIsRemovingBankHoliday(true);
            try {
              await onConfirm();
            } catch (error) {
              console.error(error);
            } finally {
              handleOnHide();
            }
          }}
        >
          <ActionSpinner
            showSpinner={isRemovingBankHoliday}
            defaultText={'Yes'}
            spinnerContent={'Removing...'}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveBankHolidayModal;
