import React from 'react';
import { Col } from 'react-bootstrap';
import EmployeeWorklogs from '../components/admin-page/EmployeeWorklogs';

export const EmployeeWorklogsView = () => {
  return (
    <Col className="bg-light overflow-auto">
      <EmployeeWorklogs />
    </Col>
  );
};

export default EmployeeWorklogsView;
