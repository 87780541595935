import { useFormik } from 'formik';
import moment from 'moment';
import React, { FC } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { MONTHS } from '../../months';
import { validationSchema } from './validationWorkHoursMonthSummary';

interface WorkHoursFormProps {
  onSubmitHandler: (values: WorkHoursFormValues) => void;
}

const initialTouched = {
  year: false,
  month: false,
};

export type WorkHoursFormValues = {
  year: number;
  month: number;
};

const WorkHoursForm: FC<WorkHoursFormProps> = ({ onSubmitHandler }) => {
  const { handleChange, handleSubmit, values, errors, touched, isSubmitting } =
    useFormik<WorkHoursFormValues>({
      initialValues: {
        year: moment().year(),
        month: moment().month() + 1,
      },
      initialTouched,
      validationSchema,
      onSubmit: onSubmitHandler,
    });

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col className="col-4">
          <Form.Group>
            <Form.Label>Select year</Form.Label>
            <Form.Control
              type="input"
              name="year"
              value={values.year}
              onChange={handleChange}
            />
            {touched.year && errors.year && (
              <span style={{ fontSize: 12 }} className="text-danger">
                {errors.year}
              </span>
            )}
          </Form.Group>
        </Col>
        <Col className="col-4">
          <Form.Group>
            <Form.Label>Select month</Form.Label>
            <Form.Control
              as="select"
              name="month"
              value={values.month}
              onChange={handleChange}
            >
              {MONTHS.map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </Form.Control>
            {touched.month && errors.month && (
              <span style={{ fontSize: 12 }} className="text-danger">
                {errors.month}
              </span>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>&nbsp;</Form.Label>
            <Button
              style={{ width: '100%', marginBottom: '10px' }}
              type="submit"
              variant="primary"
              disabled={isSubmitting}
            >
              Show results
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default WorkHoursForm;
