import { WorkHoursMonthSummaryEnum } from 'apps/client-portal/src/app/enums/WorkHoursMonthSummary.enum';

export const getStatusColor = (status: WorkHoursMonthSummaryEnum) => {
  switch (status) {
    case WorkHoursMonthSummaryEnum.EXCEED:
      return '#ea8c8f';
    case WorkHoursMonthSummaryEnum.BELOW:
      return '#fbdb75';
    case WorkHoursMonthSummaryEnum.OK:
      return '#84D59C';
    case WorkHoursMonthSummaryEnum.EMPTY:
    case WorkHoursMonthSummaryEnum.SUMMARY:
      return '#d3d3d3';
    default:
      return 'transparent';
  }
};
