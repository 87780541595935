import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {
  GlobalConfigProvider,
  AxiosProvider,
  swrConfig,
  Seo,
  toastConfig,
} from '@portal/frontend/react';
import Routing from './app/components/Routing';
import { SWRConfig } from 'swr';
import './app/assets/scss/core.scss';
import { CookiesProvider } from 'react-cookie';
import { AccessToken } from '@portal/frontend/utils';
import { ToastContainer } from 'react-toastify';

const TITLE = `Silevis Portal`;

const App = () => {
  return (
    <GlobalConfigProvider accessTokenKeyName={AccessToken.PORTAL} title={TITLE}>
      <Seo title={TITLE} />
      <CookiesProvider>
        <BrowserRouter>
          <AxiosProvider />
          <SWRConfig value={swrConfig}>
            <Routing />
            <ToastContainer {...toastConfig} />
          </SWRConfig>
        </BrowserRouter>
      </CookiesProvider>
    </GlobalConfigProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
