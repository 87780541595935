import { EmployeeDto } from '@portal/client-portal-api-model';
import { useRequest } from '@portal/frontend/react';
import { sortByName } from '@portal/frontend/utils';
import Endpoint from '../enums/apiEndpoints';

export function useEmployeeDictionary() {
  const {
    data: employeeDictionary,
    response,
    error,
    isValidating,
    mutate,
    mutateData,
  } = useRequest<EmployeeDto[]>({
    url: Endpoint.EMPLOYEES_DICTIONARY,
  });

  return {
    employeeDictionary: employeeDictionary?.sort(sortByName),
    employeeDictionaryResponse: response,
    error,
    isValidating,
    mutateEmployeesResponse: mutate,
    mutateEmployees: mutateData,
  };
}
