import React, { FC, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ActionSpinner } from '@portal/frontend/react';

interface LockTimeModalProps {
  onHide: () => void;
  onConfirm: () => Promise<void>;
  show: boolean;
}

export const LockTimeModal: FC<LockTimeModalProps> = ({
  onHide,
  show,
  onConfirm,
}) => {
  const [lockingTimeUsers, setLockingTimeUsers] = useState(false);

  const handleOnHide = () => {
    onHide();
    setLockingTimeUsers(false);
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      onHide={handleOnHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          You are going to change lock time for selected users
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        All selected users lock time will be updated. Are you sure to proceed?
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOnHide} variant="outline-primary">
          No
        </Button>
        <Button
          variant="danger"
          disabled={lockingTimeUsers}
          onClick={async () => {
            setLockingTimeUsers(true);
            try {
              await onConfirm();
            } catch (error) {
              console.error(error);
            } finally {
              handleOnHide();
            }
          }}
        >
          <ActionSpinner
            showSpinner={lockingTimeUsers}
            defaultText={'Yes'}
            spinnerContent={'Locking...'}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LockTimeModal;
