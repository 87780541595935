enum Endpoint {
  PROJECT_SETTINGS = '/projectSettings',
  MANAGER_PROJECTS = '/managerProjects',
  ROLES = '/roles',
  PROJECT_ROLES = '/roles/project',
  COLLABORATORS = '/collaborators',
  PROJECT_COLLABORATORS = '/collaborators/project',
  EMPLOYEES = '/employees',
  EMPLOYEES_DICTIONARY = '/employees/dictionary',
  WORK_ITEMS = '/workItems',
  PROJECT_WORK_ITEMS = '/workItems/project',
  WORKITEM_ROLE_HOURS = '/workItemRoleHours',
  REPORTS = '/reports',
  LOCK_TIME = '/lockTime',
  BANK_HOLIDAYS = '/bankHolidays',
  MONTH_WORK_HOURS_SUMMARY_REPORT = '/reports/monthWorkHoursSummaryReport',
}

export default Endpoint;
