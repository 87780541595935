import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { reportsRoutes } from '../utils/reports.routes';
import { ChevronRight, Table as ProjectIcon } from 'react-bootstrap-icons';
import classNames from 'classnames';

export const ReportsSidebar: FC = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-title text-align-center">Reports</div>
      <div className="sidebar-project-list">
        {reportsRoutes.map((route) => (
          <NavLink
            to={route.route}
            key={route.route}
            className={({ isActive }) =>
              classNames('sidebar-active-route', { active: isActive })
            }
          >
            <div className="sidebar-project-list-item">
              <span className="pr-3">
                <ProjectIcon />
              </span>
              {route.showChevronIcon ? (
                <>
                  {route.domain} <ChevronRight /> {route.breakdown}
                </>
              ) : (
                route.domain
              )}
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default ReportsSidebar;
