import { Column } from '@silevis/reactgrid';

export const getEmployeeTabColumns = (): Column[] => [
  { columnId: 'name', width: 200 },
  { columnId: 'surname', width: 250 },
  { columnId: 'code', width: 100 },
  { columnId: 'email', width: 200 },
  { columnId: 'role', width: 150 },
  { columnId: 'status', width: 100 },
  { columnId: 'action', width: 100 },
  { columnId: 'changePassword', width: 170 },
];
