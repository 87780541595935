import React, { FC, useEffect, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import Axios from 'axios';
import Endpoint from '../../enums/apiEndpoints';
import ProjectModal from './ProjectModal';
import {
  ProjectCollabollatorDto,
  ProjectDto,
  UpdateProjectDto,
} from '@portal/client-portal-api-model';
import {
  ActionSpinner,
  Loader,
  ToggleSwitch,
  useAccessToken,
  useCurrentUser,
  UserRole,
} from '@portal/frontend/react';
import { useProjects } from '../../hooks/useProjects';
import { PlusSquare as Add, Table as ProjectIcon } from 'react-bootstrap-icons';
import RemoveProjectModal from './RemoveProjectModal';
import ArchiveProjectModal from './ArchiveProjectModal';
import { sendRequest } from '@portal/frontend/utils';
import classNames from 'classnames';
import useScrollRestoration from '../../hooks/useScrollRestoration';

interface ProjectListProps {
  onChangeProject?: (id: string) => void;
  selectedProjectId?: string;
}

const initialModalState = { show: false, editMode: false };

export const ProjectList: FC<ProjectListProps> = ({
  onChangeProject,
  selectedProjectId,
}) => {
  useScrollRestoration('project-list-scroll');

  const { decodedToken } = useAccessToken();
  const { sub: employeeId } = decodedToken;

  const [modalShow, setModalShow] = useState(initialModalState);
  const [removeProjectModalShow, setRemoveProjectModalShow] = useState(false);
  const [archiveProjectModalShow, setArchiveProjectModalShow] = useState(false);
  const [unarchivingProject, setUnarchivingProject] = useState(0);
  const [showArchived, setShowArchived] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const currentUser = useCurrentUser();
  const { projects, mutateProjects, isValidating } = useProjects();

  useEffect(() => {
    if (!isValidating && projects && !selectedProjectId) {
      const filteredProjects = projects.filter(
        (project) => project.deletedAt === null,
      );
      onChangeProject(filteredProjects[0]?.id);
    }
  }, [isValidating, projects, selectedProjectId, onChangeProject]);

  const handleOnProjectClick = (id: string) => {
    onChangeProject(id);
  };

  const handleOnSave = async (project: UpdateProjectDto): Promise<void> => {
    if (modalShow.editMode) {
      const url = `${Endpoint.PROJECT_SETTINGS}/${project.id}`;
      const { data } = await sendRequest<UpdateProjectDto, ProjectDto>(
        url,
        {
          id: project.id,
          name: project.name,
          employeeId: project.employeeId,
        },
        'POST',
      );
      mutateProjects((projects) =>
        projects.map((project) => (project.id === data.id ? data : project)),
      );
    } else {
      const endpointUrl =
        currentUser.role === UserRole.ADMINISTRATOR
          ? Endpoint.PROJECT_SETTINGS
          : Endpoint.MANAGER_PROJECTS;
      const { data } = await sendRequest<
        UpdateProjectDto,
        ProjectDto | ProjectCollabollatorDto
      >(
        endpointUrl,
        {
          ...project,
          employeeId: employeeId,
        },
        'POST',
      );
      mutateProjects((projects) => [...projects, data]);
      onChangeProject(data.id);
    }
    setModalShow(initialModalState);
  };

  const handleDeleteProject = async (): Promise<void> => {
    await Axios.delete(`${Endpoint.PROJECT_SETTINGS}/${selectedProjectId}`);
    const filteredProjects = projects.filter(
      (project) => project.id !== selectedProjectId,
    );
    mutateProjects(() => filteredProjects);
    onChangeProject(filteredProjects.length ? filteredProjects[0].id : null);
  };

  const handleArchiveProject = async (): Promise<void> => {
    const url = `${Endpoint.PROJECT_SETTINGS}/${selectedProjectId}/archive`;
    const { data } = await sendRequest<unknown, ProjectDto>(url, {}, 'PATCH');
    mutateProjects((projects) =>
      projects.map((project) => (project.id === data.id ? data : project)),
    );
  };

  const handleUnarchiveProject = async (): Promise<void> => {
    const url = `${Endpoint.PROJECT_SETTINGS}/${selectedProjectId}/unarchive`;
    const { data } = await sendRequest<unknown, ProjectDto>(url, {}, 'PATCH');
    mutateProjects((projects) =>
      projects.map((project) => (project.id === data.id ? data : project)),
    );
  };

  const handleUnarchiveBtnClick = async (): Promise<void> => {
    setUnarchivingProject((unarchivingProject) => ++unarchivingProject);
    try {
      await handleUnarchiveProject();
    } catch (error) {
      console.error(error);
    }
    setUnarchivingProject((unarchivingProject) => --unarchivingProject);
  };

  const handleArchiveBtnClick = async (): Promise<void> => {
    setArchiveProjectModalShow(true);
  };

  const filteredProjects = projects?.filter(
    (project) =>
      project.name.toLowerCase().includes(searchValue.toLowerCase()) &&
      (showArchived || project.deletedAt === null),
  );

  const selectedProject = filteredProjects?.find(
    (project) => project.id === selectedProjectId,
  );

  return (
    <div className="sidebar">
      <div className="sidebar-title text-align-center">
        Projects
        <Button
          variant="outline-primary"
          className="ml-3 mb-3"
          size="sm"
          onClick={() => setModalShow({ ...modalShow, show: true })}
        >
          <Add className="pr-2" size="1.5em" />
          Add
        </Button>
        <ToggleSwitch
          name="Show Archived Projects"
          onChange={() => setShowArchived(!showArchived)}
          checked={showArchived}
        />
      </div>

      <div className="p-2">
        <input
          type="text"
          name="projectSearch"
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className="w-100 p-2"
          placeholder="Search projects..."
        />
      </div>

      <div
        id="project-list-scroll"
        className="sidebar-project-list overflow-auto"
      >
        {!filteredProjects && isValidating && (
          <div className={'sidebar-project-list-item'}>
            <Loader text={'Loading projects...'} />
          </div>
        )}
        {filteredProjects?.map((project) => (
          <div
            onClick={() => handleOnProjectClick(project.id)}
            key={project.id}
            className={classNames('sidebar-project-list-item', {
              active: project.id === selectedProjectId,
              archived: project.deletedAt,
            })}
          >
            <span className={'pr-2'}>
              <ProjectIcon />
            </span>
            {project.name}
            {project.deletedAt && (
              <Badge variant="secondary" className="ml-3">
                archived
              </Badge>
            )}
          </div>
        ))}
      </div>

      <div className="text-center pt-3 pb-3 d-flex justify-content-center">
        {!selectedProject?.deletedAt && (
          <Button
            disabled={!projects?.length}
            variant="success"
            style={{ width: '60px' }}
            className="mr-2"
            size="sm"
            onClick={() => {
              setModalShow({ editMode: true, show: true });
            }}
          >
            Edit
          </Button>
        )}
        {selectedProject?.deletedAt && (
          <Button
            disabled={!projects?.length}
            variant={'outline-secondary'}
            className="mr-2"
            size="sm"
            onClick={handleUnarchiveBtnClick}
          >
            <ActionSpinner
              showSpinner={unarchivingProject > 0}
              defaultText={'Unarchive'}
              spinnerContent={'Unarchiving...'}
            />
          </Button>
        )}
        {!selectedProject?.hasLoggedWorkHours &&
          !selectedProject?.deletedAt && (
            <Button
              disabled={!projects?.length}
              variant="danger"
              className="mr-2"
              size="sm"
              onClick={() => {
                setRemoveProjectModalShow(true);
              }}
            >
              Delete
            </Button>
          )}
        {!selectedProject?.deletedAt && (
          <Button
            disabled={!projects?.length}
            variant={'outline-primary'}
            className="mr-2"
            size="sm"
            onClick={handleArchiveBtnClick}
          >
            Archive
          </Button>
        )}
      </div>

      <ProjectModal
        show={modalShow.show}
        onHide={() => {
          setModalShow(initialModalState);
        }}
        onSave={(project) => handleOnSave(project)}
        project={
          modalShow.editMode &&
          projects.find((project) => project.id === selectedProjectId)
        }
      />

      <RemoveProjectModal
        show={removeProjectModalShow}
        onHide={() => {
          setRemoveProjectModalShow(false);
        }}
        onConfirm={async () => {
          await handleDeleteProject();
          setRemoveProjectModalShow(false);
        }}
        project={projects?.find((project) => project.id === selectedProjectId)}
      />

      <ArchiveProjectModal
        show={archiveProjectModalShow}
        onHide={() => {
          setArchiveProjectModalShow(false);
        }}
        onConfirm={async () => {
          await handleArchiveProject();
          setArchiveProjectModalShow(false);
        }}
        project={projects?.find((project) => project.id === selectedProjectId)}
      />
    </div>
  );
};

export default ProjectList;
